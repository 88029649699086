import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useMediaQuery} from "react-responsive";

import TopBar from "../TopBar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import Typography from "@mui/material/Typography";
import useTranslation from "../../customHooks/translations";
import {useNavigate, useParams} from "react-router-dom";
import agent from "../../agent";
import ListErrors from "../ListErrors";
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import FreakParticipantsList from "../Freak/FreakParticipantsList";
import FreakSelect from "../Freak/FreakSelect";
import {
  FREAK_HUB_SUBMITTED,
  UPLOAD_FREAK_HUB_BACKGROUND_IMAGE,
  UPLOAD_FREAK_HUB_IMAGE,
} from "../../constants/actionTypes";
import FileDropzoneArea from "../FileDropzoneArea";
import {convertToBase64} from "../../utils/imageUtils";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";

const FreakHubEditor = (props) => {
  const context = props.context;
  const device = props.device;

  const translation = useTranslation();
  const navigate = useNavigate();
  const {slug} = useParams();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const paddingLeft = (isDesktop && props.leftNavigationOpen) ? 300 : (isDesktop && !props.leftNavigationOpen ? 75 : 0);

  const [freakHubId, setFreakHubId] = useState(null);
  const [title, setTitle] = useState('');
  const [oldTitle, setOldTitle] = useState('');
  const [body, setBody] = useState('');
  const [participants, setParticipants] = useState([]);
  const [author, setAuthor] = useState(null);
  const [isAuthor, setIsAuthor] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [image, setImage] = useState('');
  const [currentImage, setCurrentImage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [currentBackgroundImage, setCurrentBackgroundImage] = useState('');
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [facebookMessengerGroupLink, setFacebookMessengerGroupLink] = useState('');
  const [isValidFacebookMessengerGroupLink, setIsValidFacebookMessengerGroupLink] = useState(true);

  useEffect(() => {
    setSaveDisabled(!(title?.trim() && body && body?.trim()));
  }, [title, body]);

  useEffect(() => {
    const fetchData = async () => {
      if (slug) {
        const formData = await agent.FreakHubs.get(slug, device);
        if (formData) {
          setFreakHubId(formData?.freakHub.id);
          setTitle(formData?.freakHub?.name || '');
          setOldTitle(formData?.freakHub?.name || '');
          setBody(formData?.freakHub?.description || '');
          setParticipants(formData?.freakHub?.participants || []);
          setAuthor(formData?.freakHub?.author);
          setIsAuthor(formData?.freakHub?.author?.username === props?.currentUser?.username);
          setImage(formData.freakHub?.image ?? '');
          setImageUrl(formData.freakHub?.imageUrl ?? '');
          setBackgroundImage(formData.freakHub?.backgroundImage ?? '');
          setBackgroundImageUrl(formData.freakHub?.backgroundImageUrl ?? '');
          setFacebookMessengerGroupLink(formData.freakHub?.facebookMessengerGroupLink ?? '');
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setImage(props.freakHub?.image ?? '');
    setImageUrl(props.freakHub?.imageUrl ?? '');
    setBackgroundImage(props.freakHub?.backgroundImage ?? '')
    setBackgroundImageUrl(props.freakHub?.backgroundImageUrl ?? '')
  }, [
    props.freakHub?.image,
    props.freakHub?.backgroundImage,
  ]);


  const onParticipantSelect = (currentParticipants, participantsToAdd, setParticipants) => {
    const updatedParticipants = currentParticipants || [];
    for (const participant of participantsToAdd) {
      const alreadyExists = updatedParticipants?.find(item => item.username === participant.username);
      if (!alreadyExists) {
        updatedParticipants.push(participant);
      }
    }
    setParticipants(updatedParticipants)
  };

  const submitForm = (event, props) => {
    event.preventDefault();

    const freakHub = {
      title: props.title,
      description: props.description,
      context: props.context,
      body: props.body,
      tagList: props.tagList || [],
      participants: props?.participants?.map(participant => participant.username) || [],
      startDate: props.startDate,
      public: props.isPrivate !== undefined ? !props.isPrivate : true,
      facebookMessengerGroupLink: props.facebookMessengerGroupLink,
    };

    if (image && image !== currentImage) {
      freakHub.image = image;
    }

    if (backgroundImage && backgroundImage !== currentBackgroundImage) {
      freakHub.backgroundImage = backgroundImage;
    }

    const slug = {slug: props.slug};
    const promise = props.slug
      ? agent.FreakHubs.update(Object.assign(freakHub, slug))
      : agent.FreakHubs.create(freakHub);

    props.onSubmit(promise);
  };

  const validateFacebookMessengerLink = (placeLink, setIsValidFacebookMessengerGroupLink, setFacebookMessengerGroupLink) => {
    const placeRegex = /https:.+/;

    const isValidLink = placeRegex.test(placeLink);

    setIsValidFacebookMessengerGroupLink(isValidLink);
    setFacebookMessengerGroupLink(placeLink);
  };

  return (
    <>
      <TopBar />
      <div
        className="editor-page"
        style={{
          paddingLeft,
        }}
      >
      </div>
      <div className="banner">
        <IconButton sx={{color: 'white'}} aria-label="back" onClick={() => {
          navigate(-1);
        }}>
          <ArrowBackIosNewOutlinedIcon sx={{
            marginRight: 2,
            color: DEFAULT_THEME_COLOR
          }}
          />
          <Typography variant="h4" sx={{color: 'white'}}>
            {title}
          </Typography>
        </IconButton>
      </div>
      <div className="container page">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-xs-12">
            <ListErrors errors={props.errors}></ListErrors>

            <form>
              <fieldset>
                <fieldset className="form-group">
                  <input
                    style={{
                      backgroundColor: 'transparent',
                      color: DEFAULT_THEME_COLOR,
                      border: '0.5px solid #F1F1F1',
                    }}
                    className="form-control form-control-lg"
                    type="text"
                    placeholder={translation.hubEditor.placeholders.title}
                    value={title}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                  />
                </fieldset>

                <fieldset className="form-group">
                  <Typography variant="h6" sx={{
                    marginTop: 0.5,
                    color: DEFAULT_THEME_COLOR,
                  }}>
                    {translation.settings.userImageLabel}
                  </Typography>
                  <FileDropzoneArea
                    onFileUpload={props.onFileUpload}
                  />
                </fieldset>

                <fieldset className="form-group">
                  <Typography variant="h6" sx={{
                    marginTop: 0.5,
                    color: DEFAULT_THEME_COLOR,
                  }}>
                    {translation.settings.backgroundImageLabel}
                  </Typography>
                  <FileDropzoneArea
                    onFileUpload={props.onBackgroundFileUpload}
                  />
                </fieldset>

                <fieldset className="form-group">
                  <TextareaAutosize
                    style={{
                      backgroundColor: 'transparent',
                      color: DEFAULT_THEME_COLOR,
                      minHeight: 200,
                      border: '0.5px solid #F1F1F1',
                    }}
                    variant="solid"
                    className="form-control form-control-lg"
                    rows="8"
                    placeholder={translation.hubEditor.placeholders.content}
                    value={body}
                    onChange={(event) => {
                      setBody(event?.target?.value);
                    }}>
                  </TextareaAutosize>
                </fieldset>

                {
                  participants && participants.length
                    ? <>
                      <hr/>
                      <FreakParticipantsList
                        freakHubId={freakHubId}
                        author={author}
                        participants={participants}
                        isAuthor={isAuthor}
                      />
                    </>
                    : null
                }

                <hr/>
                <FreakSelect
                  onSelect={(participantsToAdd => onParticipantSelect(participants, participantsToAdd, setParticipants))}
                />

                <hr/>
                <fieldset className="form-group">
                  <Grid container>
                    <Grid container sx={{
                      marginBottom: 2,
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                      <Grid item xs={2}>
                        <Avatar
                          alt="logo"
                          src="/messenger-icon.png"
                          sx={{
                            width: 50,
                            height: 50,
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          size="small"
                          label={translation.linksFacebook.placeholders.groupMessenger}
                          variant="outlined"
                          fullWidth
                          value={facebookMessengerGroupLink}
                          onChange={(e) => validateFacebookMessengerLink(e.target.value, setIsValidFacebookMessengerGroupLink, setFacebookMessengerGroupLink)}
                          error={!isValidFacebookMessengerGroupLink}
                          helperText={!isValidFacebookMessengerGroupLink && translation.linksFacebook.placeholders.groupMessenger}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          variant="contained"
                          style={{
                            height: 25,
                            outline: 'none'
                          }}
                          onClick={() => {
                            setFacebookMessengerGroupLink('');
                            setIsValidFacebookMessengerGroupLink(true);
                          }}
                        >
                          <DeleteIcon sx={{ color: DEFAULT_THEME_COLOR }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </fieldset>

                <hr/>
                <button
                  className="btn btn-lg pull-xs-right btn-primary"
                  style={{
                    marginBottom: 100,
                  }}
                  type="button"
                  disabled={saveDisabled}
                  onClick={(event) => submitForm(event, {
                    ...props,
                    slug,
                    title,
                    context,
                    oldTitle,
                    body,
                    participants,
                    facebookMessengerGroupLink,
                  })}>
                  {translation.hubEditor.save}
                </button>

              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </>
  )
};

const mapStateToProps = state => ({
  freakHub: state.freakHubEditor.freakHub,
  device: state.common.device,
  currentUser: state.common.currentUser,
  leftNavigationOpen: state.common.leftNavigationOpen,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: payload =>
    dispatch({ type: FREAK_HUB_SUBMITTED, payload }),
  onFileUpload: async payload => {
    if (payload && payload.length) {
      const image = await convertToBase64(payload[0]);
      dispatch({
        type: UPLOAD_FREAK_HUB_IMAGE,
        payload: {
          image
        }
      })
    }
  },
  onBackgroundFileUpload: async payload => {
    if (payload && payload.length) {
      const backgroundImage = await convertToBase64(payload[0]);
      dispatch({
        type: UPLOAD_FREAK_HUB_BACKGROUND_IMAGE,
        payload: {
          backgroundImage
        }
      })
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FreakHubEditor);
