import {
  FREAK_HUB_SUBMITTED,
  UPLOAD_FREAK_HUB_IMAGE,
  UPLOAD_FREAK_HUB_BACKGROUND_IMAGE,
  DELETE_FREAK_HUB,
} from "../constants/actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case FREAK_HUB_SUBMITTED:
      return {
        ...state,
        inProgress: null,
        errors: action.error ? action.payload.errors : null
      };
    case UPLOAD_FREAK_HUB_IMAGE:
      return {
        ...state,
        freakHub: {
          ...state.freakHub,
          image: action.payload.image,
        },
        inProgress: false,
      }
    case UPLOAD_FREAK_HUB_BACKGROUND_IMAGE:
      return {
        ...state,
        freakHub: {
          ...state.freakHub,
          backgroundImage: action.payload.backgroundImage,
        },
        inProgress: false,
      }
    default:
      return state;
  }
};
