import * as React from 'react';
import dayjs from 'dayjs';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import useTranslation from "../customHooks/translations";


const DatePickerComponent = (props) => {
  const translation = useTranslation();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <MobileDateTimePicker
          label={props.label || translation.datePicker.placeholders.select}
          value={props.value && props.value !== 'none' ? dayjs(props.value) : null}
          onChange={(value) => props.onChange(value?.toISOString())}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

export default DatePickerComponent;
