import React, { useState } from 'react';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import { InstantSearch, SearchBox, InfiniteHits, Configure } from 'react-instantsearch';
import SearchFreakResultItem from "./SearchFreakResultItem";
import debounce from 'lodash/debounce';

import "./styles.css";
import useTranslation from "../../customHooks/translations";
import SearchUserResultItem from "./SearchUserResultItem";
import SearchFreakHubResultItem from "./SearchFreakHubResultItem";
import {
  ALGOLIA_INDEX_NAME_FREAK_HUBS,
  ALGOLIA_INDEX_NAME_FREAK_PLACES,
  ALGOLIA_INDEX_NAME_FREAKS,
  ALGOLIA_INDEX_NAME_USERS
} from "../../constants/commonConstants";
import SearchFreakPlaceResultItem from "./SearchFreakPlaceResultItem";
import { useMediaQuery } from "react-responsive";

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);

const SearchComponent = (props) => {
  const translation = useTranslation();
  const [hasQuery, setHasQuery] = useState(props.loadOnStart || false);
  const isDesktop = useMediaQuery({minWidth: 992});

  const debouncedSearch = debounce((query, search) => {
    setHasQuery(query.length > 0);
    search(query);
  }, 500);

  const getResultsStyles = () => {
    return isDesktop
     ? {
        position: props.freakPlacesRestricted ? 'absolute' : 'static',
        left: 50,
        right: 50,
        top: '100%',
        zIndex: 1000,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        maxHeight: '90vh',
        overflowY: 'auto',
      }
     : {
        position: 'relative',
        left: '0',
        right: '0',
        zIndex: 1000,
        backgroundColor: 'black',
        maxHeight: '90vh',
        overflowY: 'auto',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      };
  };

  return (
    <InstantSearch
      indexName={props.indexName}
      searchClient={searchClient}
    >
      <Configure filters={`context:${props.context}`} />
      <SearchBox
        style={{
          width: '100%',
          maxWidth: '600px',
          margin: '10px auto 20px',
          padding: '10px 15px',
          border: '2px solid #ddd',
          borderRadius: '25px',
          fontSize: '16px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          outline: 'none',
        }}
        placeholder={translation.algoliaSearch.search}
        autoFocus
        queryHook={(query, search) => debouncedSearch(query, search)}
      />
      {
        hasQuery && (
          <div
            style={getResultsStyles()}
          >
            <InfiniteHits
              showPrevious={false}
              hitComponent={hit => {
                const hitData = hit.hit;
                if (props.indexName === ALGOLIA_INDEX_NAME_FREAKS) {
                  return <SearchFreakResultItem item={hitData} />
                } else if (props.indexName === ALGOLIA_INDEX_NAME_USERS) {
                  return <SearchUserResultItem item={hitData} onSelect={props.onSelect} />
                } else if (props.indexName === ALGOLIA_INDEX_NAME_FREAK_PLACES) {
                  return <SearchFreakPlaceResultItem
                    item={hitData}
                    handleCardClick={() => props.handleCardClick(hitData)}
                    setHasQuery={setHasQuery}
                  />
                } else if (props.indexName === ALGOLIA_INDEX_NAME_FREAK_HUBS) {
                  return <SearchFreakHubResultItem item={hitData} />
                } else {
                  return (
                    <div>
                      Not supported!
                    </div>
                  );
                }
              }}
            />
          </div>

        )
      }
    </InstantSearch>
  );
};

export default SearchComponent;
