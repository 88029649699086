export default {
  tabs: {
    private: 'Moje',
    public: 'Publiczne',
    nearby: 'Pobliskie',
    followed: 'Obserwuje',
    daily: 'Feed',
    all: 'Wszystkie',
    myHubs: 'Moje',
    exploreHubs: 'Eksploruj'
  },
  comments: {
    placeholder: 'Napisz komentarz...',
    add: 'Wyślij',
    postComment: 'Dodaj komentarz',
    reply: 'Odpowiedz',
    cancel: 'Anuluj',
  },
  buttons: {
    edit: 'Edytuj',
    delete: 'Usuń',
    finish: 'Zakończ',
    publish: 'Opublikuj',
    cancel: 'Anuluj',
    chat: 'Wiadomości',
    leave: 'Opuść'
  },
  accordions: {
    addSocialMedia: 'Dodaj Social Media'
  },
  freaks: {
    visibility: {
      public: 'Publiczny',
      private: 'Prywatny',
      daily: 'Dzienny',
      weekly: 'Tygodniowy',
      monthly: 'Miesięczny',
      yearly: 'Roczny',
      campaign: 'Kampania',
      promotion: 'Promowanie',
      freak_hub: 'Freakowisko'
    },
    status: {
      inProgress: 'W trakcie',
      finished: 'Zakończony',
      planned: 'Zaplanowany',
      canceled: 'Anulowany'
    },
    operations: {
      start: 'Rozpocznij',
      join: 'Dołącz',
      requested: 'Wysłano'
    },
    participate: {
      pending: 'W trakcie',
      approved: 'Zaakceptowane',
      declined: 'Odrzucone',
      requestJoin: 'Wysłane'
    },
    emptyList: 'Brak freaków do wyświetlenia.',
    emptyListDaily: 'Brak feed do wyświetlenia. Stwórz swój Feed ! ;)',
    emptyListFollowed: 'Brak Freaków do wyświetlenia',
    deleted: 'Freak został usunięty ;(',
    unauthorized: 'Musisz zaakceptować zaproszenie by wyświetlić tego Freak',
    favourited: 'Polubione',
    loading: 'Ładowanie Freaków'
  },
  profile: {
    editMessage: 'Edytuj',
    freaks: 'Freaków',
    followers: 'Obserwujących',
    following: 'Obserwuje',
    points: 'Freak Punktów',
    verified: 'freak-land.com',
    prizeHistory: 'Moje Nagrody',
    topFeeds: 'Top Feeds',
    freakFeeds: 'Aktywny/a w Freak Feeds',
    freakHubs: 'Aktywny/a w Freakowiskach',
    mainContext: 'Główny kontekst',
  },
  articles: {
    my: 'Moje Artykuły',
    favourited: 'Polubione Artykuły',
    emptyList: 'Brak artukułów do wyświetlenia.'
  },
  settings: {
    title: 'Ustawienia',
    logout: 'Wyloguj',
    shakeIt: 'Shake It!',
    forceReload: 'Pobierz najnowszą wersję',
    version: 'Wersja',
    update: 'Zapisz',
    bio: 'Napisz coś o sobie',
    bioLabel: 'Bio',
    userImageLabel: 'Zdjęcie profilowe',
    backgroundImageLabel: 'Zdjęcie tła',
    modeLabel: 'Tryb',
    modeLabelDescription: 'Zmień tryb na ciemny.',
    languageLabel: 'Język',
    languageLabelDescription: 'Wybierz język.',
    languagePolish: 'Polski',
    languageEnglish: 'Angielski',
    contextLabel: 'Domyślny Kontekst',
    settings: 'Ustawienia',
    notifications: 'Ustawienia notyfikacji',
    contextWof: 'Wild Off Road',
    contextBike: 'Kolarstwo',
    contextFishing: 'Wędkarstwo',
    contextTrip: 'Podróże',
    contextFit: 'Fit',
    contextRunning: 'Bieganie',
    contextDogs: 'Zwierzaki',
    contextGames: 'Gry',
    contextBooks: 'Książki',
    contextFood: 'Kuchnia',
    contextShopping: 'Promki',
    contextTech: 'Technologie',
    contextMotorization: 'Motoryzacja',
    contextGardening: 'Ogrodnictwo',
    contextMusic: 'Muzyka',
    contextSport: 'Sport',
    contextWinterSports: 'Sporty Zimowe',
    contextHorseRiding: 'Jazda Konna',
    contextMovies: 'Filmy',
    contextSeries: 'Seriale',
    switchThemeDark: 'Zmień na tryb ciemny',
    switchThemeLight: 'Zmień na tryb jasny',
    theme: 'Motyw'
  },
  modals: {
    confirmation: {
      areYouSure: 'Czy napewno chcesz wykonać ?',
      actionUndone: 'Nie można będzie cofnąć tej operacji.',
      confirm: 'Potwierdź',
      decline: 'Anuluj',
      emailHasBeenSent: 'Email potwierdzający został wysłany.',
      ok: 'OK'
    },
    tooManyRequests: {
      niceTry: 'Niezła próba 😉 Niestety, ale przekroczyłeś dzienny limit. Wróć do nas jutro, albo...',
      youHaveReachedYourLimit: 'Przekroczyłeś dzienny limit. Wróć do nas jutro, albo...',
      register: 'Zarejestruj się za darmo, aby dostać pełny dostęp 🥳',
      buyMeACoffee: 'Jeśli podoba Ci się nasza aplikacja 🙃',
      newsletter: 'Zapisz się na Newsletter'
    }
  },
  editor: {
    placeholders: {
      postTitle: 'Tytuł',
      postContent: 'Treść'
    },
    save: 'Zapisz'
  },
  freakEditor: {
    placeholders: {
      freakTitle: 'Tytuł',
      freakContent: 'Treść'
    },
    defaults: {
      body: 'Mój nowy Freak!'
    },
    add: 'Dodaj',
    save: 'Zapisz',
    isPrivateLabel: 'Zasięg Freaka',
    private: 'Prywatny',
    public: 'Publiczny',
    freakVisibility: 'Ustawienia',
    createPlace: 'Stwórz Prywatne Miejsce',
    showInPlaces: 'Pokaż w Miejscach',
    createFreaks: 'Stwórz Freaky!',
    createTrack: 'Stwórz trasę Freaka!',
    freakRouteMobileAppNeeded: 'Zainstaluj aplikację mobilną📱lub na zegarek⌚i twórz Freak trasy.',
    freakyMobileAppNeeded: 'Zainstaluj aplikację mobilną📱lub na zegarek⌚i twórz Freaksy.'
  },
  campaignEditor: {
    placeholders: {
      freakTitle: 'Tytuł',
      freakContent: 'Treść'
    },
    add: 'Dodaj',
    save: 'Zapisz',
    isPrivateLabel: 'Prywatna Kampania',
    freakVisibility: 'Ustawienia'
  },
  freakPlacesEditor: {
    placeholders: {
      title: 'Nazwa',
      content: 'Opis'
    },
    isPrivateLabel: "Prywatna miejscówka",
    private: 'Prywatne',
    public: 'Publiczne'
  },
  freakCampaigns: {
    noCampaigns: 'Brak aktywnych kampanii reklamowych.',
    contact: 'Chcesz stworzyć kampanię?',
    contactButton: 'Skontaktuj się z nami!',
    winPrice: 'Przejdź do Freak Shake!',
    everyDayWin: 'Codziennie coś do wygrania!'
  },
  freakView: {
    filter: 'Filtruj:',
    search: 'Wyszukaj...',
    yourLocation: 'Twoja lokalizacja',
    sortBy: 'Sortuj:',
    likes: 'Polubienia',
    status: 'Status',
    planned: 'Zaplanowany',
    canceled: 'Anulowany',
    finished: 'Zakończony',
    inProgress: 'W trakcie',
    date: 'Dacie utworzenia',
    dateFrom: 'Data od',
    dateTo: 'Data do',
    asc: 'Rosnąco',
    desc: 'Malejąco',
    none: 'Brak',
    participant: 'Uczestnik/Autor'
  },
  freakPreview: {
    messages: {
      plannedTo: 'Zaplanowany na',
      finishedAt: 'Zakończony',
      alreadyRunning: 'W trakcie'
    },
    copy: {
      copyLink: 'Skopiuj link',
      copied: 'Skopiowano!',
      failed: 'Nieudało się skopiować'
    },
    share: {
      checkoutThis: 'Sprawdź to !'
    }
  },
  freakDetails: {
    localization: 'Lokalizacja',
    localizationDescription: 'Miejsce wydarzenia',
    plannedTo: 'Planowany start:',
    startedAt: 'Czas startu:',
    finishedAt: 'Czas zakończenia:',
    duration: 'Czas trwania:',
    checkOnGoogleMaps: 'Sprawdź na Google Maps',
    noParticipants: 'Brak uczestników...',
    participants: 'Uczestnicy',
    participantsDescription: 'Freaki biorące udział w wydarzeniu',
    description: 'Opis',
    linkToArticle: 'Link do strony',
    information: 'Informacje',
    informationDescription: 'Informacje o wydarzeniu',
    socialMedia: 'Social Media',
    socialMediaDescription: 'Youtube, Facebook, Instagram',
    likes: 'Polubienia',
    route: 'Trasa',
    activity: 'Statystyki'
  },
  freakParticipants: {
    remove: 'Usuń'
  },
  freakSelect: {
    placeholders: {
      selectParticipants: 'Wybierz uczestników',
      searchParticipants: 'Wpisz nazwę użytkownika',
      selectPlace: 'Wybierz miejsce',
      searchPlace: 'Wpisz nazwę miejsca',
    }
  },
  freakHubSelect: {
    placeholders: {
      selectFreakHub: 'Wybierz Freakowisko',
      searchFreakHub: 'Wpisz nazwę Freakowiska',
    }
  },
  freakLocalization: {
    placeholders: {
      search: 'Wybierz lokalizację...'
    },
    yourLocalization: 'Twoja lokalizacja',
    distance: 'W odległości od Ciebie',
    all: 'Wszystkie',
    markerIsDraggable: 'Zamkij to okno i przenieś pinezke',
    clickToDragMarker: 'Kliknij tu, żeby edytować pinezke',
    filter: {
      header: 'Filtruj',
      status: 'Status',
      participant: 'Uczestnik',
      localization: 'Lokalizacja'
    },
    sort: {
      header: 'Sortuj',
      likes: 'Polubione',
      date: 'Data'
    }
  },
  freakCampaign: {
    participantsDescription: 'Freaki biorące udział w kampanii',
    cooperation: 'Współpraca',
    cooperationBody: 'Chcesz tworzyć własne Kampanie?',
    contact: 'Napisz do nas',
    requestContact: 'lub zamów kontakt a my odezwiemy się do Ciebie'
  },
  notifications: {
    types: {
      invitation: 'Zaproszenie',
      info: 'Informacja',
      requestJoin: 'Prośba o dołączenie'
    },
    emptyList: 'Nie masz żadnych powiadomień',
    accept: 'Zaakceptuj',
    decline: 'Odrzuć',
    show: 'Wyświetl',
    delete: 'Usuń',
    denied: 'Notyfikacje zablokowane.',
    settings: 'Ustawienia notyfikacji'
  },
  giphy: {
    placeholders: {
      search: 'Wyszukaj GIFy'
    }
  },
  uploadFiles: {
    upload: 'Wybierz pliki (png, jpg, jpeg, heic). Limit 10mb.',
    notSupportedFileType: 'Nie wspierany format pliku',
    somethingWentWrong: 'Coś poszło nie tak, spróbuj jeszcze raz',
    tooLargeFile: 'Zbyt duży plik. Limit 10mb.'
  },
  datePicker: {
    placeholders: {
      select: 'Wybierz datę'
    }
  },
  login: {
    needAccount: 'Nie masz konta?',
    tryUsFirst: 'lub wypróbuj nas wcześniej 🙃',
    signIn: 'Logowanie',
    password: 'Hasło',
    forgotPassword: 'Zresetuj hasło',
    buttons: {
      signIn: 'Zaloguj się'
    }
  },
  resetPassword: {
    label: 'Zresetuj hasło',
    sendEmail: 'Wyślij Email'
  },
  setupPassword: {
    setupNew: 'Ustaw nowe hasło',
    placeholders: {
      password: 'Hasło',
      confirmPassword: 'Potwierdź hasło'
    },
    confirm: 'Potwierdź'
  },
  register: {
    signUp: 'Zarejestruj się',
    haveAnAccount: 'Masz konto?',
    emailSent: 'Email z linkiem do potwierdzenia konta został wysłany.',
    termsOfService: 'Rejestrując się w celu utworzenia konta akceptuję',
    termsOfServiceTitle: 'Warunki korzystania z usługi',
    termsOfServiceAnd: 'i',
    privacyPolicy: 'Politykę Prywatności',
    placeholders: {
      username: 'Nazwa użytkownika',
      password: 'Hasło'
    },
    defaultContext: 'Freak'
  },
  readMoreComponent: {
    more: 'Więcej...',
    less: 'Mniej...'
  },
  linksPlace: {
    placeholders: {
      add: 'Dodaj link tutaj...',
      invalidLink: 'Nieprawidłowy link',
    }
  },
  linksYoutube: {
    placeholders: {
      add: 'Dodaj link YouTube...',
      addFeed: 'Dodaj kanał YouTube...',
      invalidLink: 'Nieprawidłowy link',
      invalidFeed: 'Nieprawidłowy kanał'
    }
  },
  linksFacebook: {
    placeholders: {
      add: 'Wklej link Facebook...',
      invalidLink: 'Nieprawidłowy link',
      groupMessenger: 'Dodaj link do Messengera Twojej Grupy...'
    }
  },
  linksInstagram: {
    placeholders: {
      add: 'Wklej link Instagram...',
      invalidLink: 'Nieprawidłowy link'
    }
  },
  bottomNavigation: {
    freaks: 'Freak',
    notifications: 'Powiadomienia',
    messages: 'Chat',
    places: 'Miejsca',
    campaigns: 'Kampanie',
    record: 'Freak'
  },
  leftNavigation: {
    add: 'Dodaj',
    freaks: 'Freak',
    notifications: 'Powiadomienia',
    messages: 'Chat',
    places: 'Miejsca',
    hub: 'Freakowisko',
    campaigns: 'Kampanie',
    topFreaks: 'Top Freaks',
    context: 'Kontekst',
    filters: 'Filtry',
    profile: 'Profil'
  },
  freakPlaces: {
    navigate: 'Sprawdź na:',
    details: 'Szczegóły',
    edit: 'Edytuj',
    sendForPublic: 'Wyślij, żeby zrobić publiczne',
    waitingForApproval: 'Oczekuje na akceptacje',
    delete: 'Usuń',
    freaksFromThisPlace: 'Z tego miejsca:',
    noFreaksFromThisPlace: 'Brak Freaków z tego miejsca.',
    restrictedView: 'Zarejestruj się żeby uzyskać pełny dostęp!',
    restrictedView2: 'Odblokuj możliwości po utworzeniu konta.',
    restrictedView3: 'Masz już konto?',
    registerToGetFullAccess: 'Zarejestruj się',
    fishing_pzw: 'PZW',
    fishing_commercial: 'Komercyjny',
    fishing_white_card_only: 'Tylko biała karta',
    wof_motocross: 'Off-road',
    winter_sports_ski_resort: 'Stok Narciarski',
    winter_sports_snowpark: 'Snowpark',
    loadingFreakPlaces: 'Ładowanie Miejsc...'
  },
  contextSwipeableDrawerComponent: {
    title: 'Kontekst',
    close: 'Zamknij',
    resetAllFilters: 'Zresetuj Filtry'
  },
  filterSwipeableDrawerComponent: {
    title: 'Ustawienia'
  },
  snackbar: {
    open: 'Otwórz',
    denied: 'Notyfikacje zablokowane.'
  },
  emailConfirmed: {
    message: 'Email potwierdzony.',
    buttonMessage: 'Zaloguj'
  },
  reportAbuse: {
    report: 'Zgłoś nadużycie',
    spam: 'Spam lub Scam',
    hate: 'Zawiera mowę nienawiści lub atakuje osobę',
    violence: 'Agresywne zachowania',
    nudity: 'Nagość, pornografia lub treści o charakterze jednoznacznie seksualnym',
    duplicate: 'Zduplikowane, fałszywe lub błędnie sklasyfikowane treści'
  },
  topFreaks: {
    title: "Top Freak'ów",
    points: "Freak punktów"
  },
  topFeeds: {
    title: "Top Feeds",
  },
  followedBy: {
    title: 'Twoi Obserwujący',
    noBio: 'Brak bio'
  },
  following: {
    title: 'Obeserwujesz',
    noBio: 'Brak bio'
  },
  profileFavorites: {
    follow: 'Obserwuj',
    unfollow: 'Przestań obserwować'
  },
  usersList: {
    title: 'Użytkownicy'
  },
  errors: {
    error401Message: '401 Przejdź do Freak.',
    error404Message: 'Przejdź do Freak.',
  },
  shortsCreator: {
    retake: 'Zrób nowy',
    save: 'Zapisz'
  },
  freakLocalizationTracker: {
    distance: 'Dystans:',
    duration: 'Czas:',
    movingTime: 'Czas:',
    speed: 'Prędkość',
    maxSpeed: 'Prędkość Maks.:',
    avgSpeed: 'Średnia Prędkość:',
    altitude: 'Wysokości:',
    maxAltitude: 'Max Wysokości:',
    calories: 'Calories:',
    hydration: 'Hydration:',
    avgHeartRate: 'Średnie tętno:',
    maxHeartRate: 'Maks. tętno:'
  },
  freakTrackerView: {
    resume: 'Wznów',
    start: 'Start',
    pause: 'Pauza',
    finish: 'Zakończ'
  },
  freakPlacesList: {
    fromThisPlace: 'Freaków z tego miejsca:',
    filter: 'Filtruj',
  },
  cometChat: {
    CHATS: 'Czaty',
    USERS: 'Użytkownicy',
    DELETE: 'Tak',
    CANCEL: 'Anuluj',
    DELETE_CONVERSATION: 'Usunięcie konwersacji',
    WOULD__YOU_LIKE_TO_DELETE_THIS_CONVERSATION: 'Czy chcesz usunąć tą konwersacje?'
  },
  feeds: {
    title: "Feeds",
    subscribe: 'Subskrybuj'
  },
  shake: {
    shakeToWin: 'Wstrząśnij by wygrać!',
    win: 'Twoja nagroda to:',
    onlyOnePerDay: 'Masz tylko jeden shake dziennie, wróć tu jutro ;D',
    permissions: 'Proszę o udzielenie pozwolenia na wykrywanie ruchu urządzenia.',
    loading: 'Przeliczanie nagrody... Wróć proszę za chwilę lub przeładuj stronę',
    visitShop: 'Odwiedź nasz Freak Sklep!'
  },
  hubs: {
    view: 'Zobacz',
    join: 'Dołącz',
    emptyList: 'Brak Freakowisk',
    privateView: 'Dostępne tylko dla członków Freakowiska',
    editMessage: 'Edytuj',
    deleteMessage: 'Usuń'
  },
  hubDetails: {
    freaksCount: 'Freak postów',
    usersCount: 'Użytkowników',
    createdAt: 'Utworzona',
    join: 'Dołącz'
  },
  hubEditor: {
    defaults: {
      title: 'Freakowisko',
      body: 'Nowe Freakowisko!'
    },
    placeholders: {
      title: 'Tytuł',
      content: 'Opis'
    },
    save: 'Zapisz'
  },
  hubUsers: {
    title: 'Użytkownicy w'
  },
  algoliaSearch: {
    search: 'Szukaj...'
  }
};
