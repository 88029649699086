import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DEFAULT_THEME_COLOR } from '../constants/theme';
import useTranslation from "../customHooks/translations";

const ReadMoreComponent = ({ text, maxChars = 100, hideReadMore, fontSize }) => {
  const translation = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="read-more">
      <Typography
        sx={{ whiteSpace: 'pre-line' }}
        style={{
          color: '#999',
          fontSize: fontSize ? fontSize : '0.9rem',
      }}
        dangerouslySetInnerHTML={{
          __html: expanded ? text : `${text?.slice(0, maxChars)}${text?.length > maxChars ? '...' : ''}`,
        }}
      />
      {text?.length > maxChars && !hideReadMore && (
        <Button
          onClick={handleToggle}
          style={{
            color: DEFAULT_THEME_COLOR
          }}
        >
          {expanded ? translation.readMoreComponent.less : translation.readMoreComponent.more}
        </Button>
      )}
    </div>
  );
};

export default ReadMoreComponent;
