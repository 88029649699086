import React from "react";
import {
  Box,
  Typography,
  Container,
  Link,
} from "@mui/material";
import useTranslation from "../customHooks/translations";
import {DEFAULT_THEME_COLOR} from "../constants/theme";

const GetFullAccessComponent = props => {
  const translation = useTranslation();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 8,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" fontWeight="bold" gutterBottom align="center">
          {translation.freakPlaces.restrictedView}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          gutterBottom
          align="center"
        >
          {translation.freakPlaces.restrictedView2}
        </Typography>
        <Box mt={2} textAlign="center">
          <Typography variant="body2">
            {translation.freakPlaces.restrictedView3}{" "}
            <Link href="/login" underline="hover" sx={{ color: DEFAULT_THEME_COLOR }}>
              Login
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default GetFullAccessComponent;
