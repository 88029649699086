import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';

import FreakView from './FreakView';
import {
  APPLY_TAG_FILTER,
  FREAKS_PAGE_LOADED,
  FREAKS_PAGE_UNLOADED
} from '../../constants/actionTypes';
import TopBar from "../TopBar";
import { useNavigate } from "react-router-dom";
import {getLatLon} from "../../utils/freakUtils";

const Freak = (props) => {
  const navigate = useNavigate();
  const device = props.device;
  const context = props.context;
  const [geolocation, setGeolocation] = useState(null);
  const [errorGeoLocation, setGeoErrorLocation] = useState(null);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    const fetchGeoLocation = async () => {
      await getLatLon(setGeolocation, setGeoErrorLocation);
    };
    fetchGeoLocation();
  }, [
    props.context,
    props.distanceFilter,
    props.sortByLikesFilter,
    props.sortByDateFilter,
    props.filterByNameFilter,
    props.filterByDateFilter,
    props.filterByStatusFilter,
    props.filterByParticipantFilter,
  ]);

  useEffect(() => {
    // if (!props.token) {
    //   navigate('/login');
    // }

    if (geolocation) {
      setLatitude(geolocation.latitude);
      setLongitude(geolocation.longitude);
    }
  }, [
    props.context,
    props.distanceFilter,
    props.sortByLikesFilter,
    props.sortByDateFilter,
    props.filterByNameFilter,
    props.filterByDateFromFilter,
    props.filterByDateToFilter,
    props.filterByStatusFilter,
    props.filterByParticipantFilter,
  ]);

  const isDesktop = props.device === 'desktop';

  return (
    <>
      <TopBar />
      <div className="home-page">
        <div
          className="container page"
          style={isDesktop ? {
            display: 'flex',
            justifyContent: 'center'
          } : {}}
        >
          <div
            className="row"
            style={isDesktop ? {
              maxWidth: 500,
            }: {}}
          >
            <FreakView
              context={context}
              setSelectedTab={setSelectedTab}
              initializing={props.initializing}
            />
          </div>
        </div>
      </div>
    </>
  )
};

const mapStateToProps = state => ({
  ...state.home,
  appName: state.common.appName,
  token: state.common.token,
  device: state.common.device,
  distanceFilter: state.common.distanceFilter,
  sortByLikesFilter: state.common.sortByLikesFilter,
  sortByDateFilter: state.common.sortByDateFilter,
  filterByNameFilter: state.common.filterByNameFilter,
  filterByDateFromFilter: state.common.filterByDateFromFilter,
  filterByDateToFilter: state.common.filterByDateToFilter,
  filterByStatusFilter: state.common.filterByStatusFilter,
  filterByParticipantFilter: state.common.filterByParticipantFilter,
  latitudeFilter: state.common.latitudeFilter,
  longitudeFilter: state.common.longitudeFilter,
});

const mapDispatchToProps = dispatch => ({
  onClickTag: (tag, pager, payload) =>
    dispatch({ type: APPLY_TAG_FILTER, tag, pager, payload }),
  onLoad: (tab, pager, payload) =>
    dispatch({ type: FREAKS_PAGE_LOADED, tab, pager, payload }),
  onUnload: () =>
    dispatch({  type: FREAKS_PAGE_UNLOADED })
});

export default connect(mapStateToProps, mapDispatchToProps)(Freak);
