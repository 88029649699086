import React, {useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import agent from '../agent';
import {connect} from 'react-redux';
import {
  FOLLOW_USER,
  UNFOLLOW_USER,
  PROFILE_PAGE_LOADED,
  PROFILE_PAGE_UNLOADED,
  LOGOUT,
} from '../constants/actionTypes';
import TopBar from "./TopBar";
import useTranslation from "../customHooks/translations";
import FreakList from "./Freak/FreakList";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import {DEFAULT_THEME_COLOR} from "../constants/theme";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import LogoutIcon from '@mui/icons-material/Logout';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useAuth0 } from "@auth0/auth0-react";
import ButtonGroup from "@mui/material/ButtonGroup";
import {getFreakIcon} from "../utils/freakUtils";
import {createTheme, styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const lightTheme = createTheme();
const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 50,
  lineHeight: '60px',
}));

const EditProfileSettings = props => {
  const translation = useTranslation();
  const navigate = useNavigate();
  if (props.isUser) {
    return (
      <Button
        variant="outlined"
        style={{
          backgroundColor: DEFAULT_THEME_COLOR,
          color: 'white',
          borderColor: DEFAULT_THEME_COLOR,
          'z-index': '0',
          borderRadius: 4,
          marginBottom: 10,
          outline: 'none',
        }}
        startIcon={<EditIcon/>}
        onClick={() => navigate('/settings')}
      >
        {translation.profile.editMessage}
      </Button>
    );
  }
  return null;
};

const PricesHistory = props => {
  const translation = useTranslation();
  const navigate = useNavigate();
  if (props.isUser) {
    return (
      <Button
        variant="outlined"
        style={{
          backgroundColor: DEFAULT_THEME_COLOR,
          color: 'white',
          borderColor: DEFAULT_THEME_COLOR,
          'z-index': '0',
          borderRadius: 4,
          marginBottom: 10,
          outline: 'none',
        }}
        startIcon={<EmojiEventsIcon sx={{ color: 'gold' }}/>}
        onClick={() => navigate('/prices-history')}
      >
        {translation.profile.prizeHistory}
      </Button>
    );
  }
  return null;
};

const TopFeeds = props => {
  const translation = useTranslation();
  const navigate = useNavigate();
  if (props.isUser) {
    return (
      <Button
        variant="outlined"
        style={{
          backgroundColor: DEFAULT_THEME_COLOR,
          color: 'white',
          borderColor: DEFAULT_THEME_COLOR,
          'z-index': '0',
          borderRadius: 4,
          marginBottom: 10,
          outline: 'none',
        }}
        startIcon={<YouTubeIcon sx={{
          color: '#FF0000',
          width: '20px',
          height: '20px',

        }}/>}
        onClick={() => navigate('/top-feeds')}
      >
        {translation.profile.topFeeds}
      </Button>
    );
  }
  return null;
};

const Logout = props => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  if (props.isUser) {
    return (
      <Button
        variant="outlined"
        style={{
          backgroundColor: DEFAULT_THEME_COLOR,
          color: 'white',
          borderColor: DEFAULT_THEME_COLOR,
          'z-index': '0',
          borderRadius: 4,
          marginBottom: 10,
          outline: 'none',
        }}
        size={'small'}
        startIcon={<LogoutIcon/>}
        onClick={() => {
          logout();
          props.onLogout();
          navigate('/login')
        }}
      >
        {translation.settings.logout}
      </Button>
    );
  }
  return null;
};

const FollowUserButton = props => {
  const translation = useTranslation();

  if (props.isUser) {
    return null;
  }

  const handleClick = ev => {
    ev.preventDefault();
    if (props.user.following) {
      props.unfollow(props.user.username)
    } else if (props.follow) {
      props.follow(props.user.username)
    }
  };

  return (
    <Button
      variant="outlined"
      style={{
        backgroundColor: DEFAULT_THEME_COLOR,
        color: 'white',
        borderColor: DEFAULT_THEME_COLOR,
        'z-index': '0',
        borderRadius: 4,
        marginBottom: 10,
        outline: 'none',
      }}
      onClick={handleClick}
    >
      {props.user.following ? translation.profileFavorites.unfollow : translation.profileFavorites.follow} {props.user.username}
    </Button>
  );
};


const renderTabs = (props) => {
  const translation = useTranslation();
  const tabName = props.profile.username !== props.currentUser.username ? props.profile.username : translation.tabs.private;
  return (
    <ul className="nav nav-pills outline-active">
      <li className="nav-item">
        <Link
          className="nav-link active"
          to={`/${props.profile.username}`}>
          {tabName}
        </Link>
      </li>
    </ul>
  );
}

const Profile = (props) => {
  const params = useParams();
  const translation = useTranslation();
  const navigate = useNavigate();
  const context = props.context || props?.currentUser?.context || 'wof';
  const username = params.username.replace('@', '');

  useEffect(() => {
    props.onLoad(Promise.all([
      agent.Profile.get(username),
      agent.Freaks.feed(
        0,
        context,
        'desktop',
        undefined,
        undefined,
        100,
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        username
      )
    ]));

    props.onUnload();
  }, []);

  const profile = props.profile;
  if (!profile) {
    return null;
  }

  const isUser = props.currentUser &&
    props.profile.username === props.currentUser.username;

  const isDesktop = props.device === 'desktop';

  return (
    <>
      <TopBar/>
      <div className="profile-page" style={isDesktop ? {
        maxWidth: 500,
        marginLeft: "auto",
        marginRight: "auto",
      } : {}}>
        <Box>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-10 offset-md-1">
                <Box
                  sx={{
                    background: '#f3f3f3',
                    backgroundImage: props?.profile?.backgroundImageUrl ? `url("${props.profile.backgroundImageUrl}")` : '',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: 5,
                    height: '100px',
                  }}
                >
                  <Avatar
                    sx={{
                      width: '100px',
                      height: '100px',
                      borderRadius: 5,
                    }}
                    alt={profile.username}
                    src={profile?.imageUrl}
                  />
                </Box>

                <Box sx={{
                  marginTop: 1,
                  marginLeft: 1,
                  marginBottom: 1,
                }}>
                  <h3>{profile.username}</h3>
                  <p style={{color: 'grey'}}>{`@${profile.username}`}</p>

                  <Grid
                    container
                    spacing={2}
                    alignItems="left"
                    sx={{
                      marginTop: 1,
                      marginBottom: 3,
                    }}
                  >
                    <Grid item xs={0.5}>
                      <Avatar
                        sx={{
                          width: 14,
                          height: 14,
                          marginTop: 0.5,
                        }}
                        src={'./../icon-48x48.png'}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Link
                        style={{
                          textDecoration: 'underline'
                        }}
                        to={'https://www.freak-land.com'}
                        target="_blank"
                      >
                        {translation.profile.verified}
                      </Link>

                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={0}
                    alignItems="left"
                    sx={{
                      marginTop: 4,
                      marginBottom: 3,
                    }}
                  >
                    <Grid container xs={6}>
                      <Grid item sx={{
                        fontWeight: 'bold',
                        marginRight: 2,
                      }}>
                        {profile.freaksCount}
                      </Grid>
                      <Grid item sx={{
                        marginTop: 0.35,
                        fontSize: 13,
                      }}>
                        {translation.profile.freaks}
                      </Grid>
                    </Grid>
                    <Grid container xs={6}>
                      <Grid item sx={{
                        fontWeight: 'bold',
                        marginRight: 2,
                      }}>
                        {profile.points}
                      </Grid>
                      <Grid item sx={{
                        marginTop: 0.35,
                        fontSize: 13,
                      }}>
                        {translation.profile.points}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    alignItems="left"
                    sx={{
                      marginTop: 1,
                      marginBottom: 3,
                    }}
                  >
                    <Grid
                      container
                      xs={6}
                      onClick={() => navigate(`/followed-by/@${username}`)}
                    >
                      <Grid item sx={{
                        fontWeight: 'bold',
                        marginRight: 2,
                      }}>
                        {profile.followedByCount}
                      </Grid>
                      <Grid item sx={{
                        marginTop: 0.35,
                        fontSize: 13,
                      }}>
                        {translation.profile.followers}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={6}
                      onClick={() => navigate(`/following/@${username}`)}
                    >
                      <Grid item sx={{
                        fontWeight: 'bold',
                        marginRight: 2,
                      }}>
                        {profile.followingCount}
                      </Grid>
                      <Grid item sx={{
                        marginTop: 0.35,
                        fontSize: 13,
                      }}>
                        {translation.profile.following}
                      </Grid>
                    </Grid>
                  </Grid>

                  <p style={{
                    color: 'grey'
                  }}>{profile.bio}</p>
                </Box>

                <div style={{ fontSize: 13, marginBottom: 2, }}>{translation.profile.mainContext}:</div>
                <Grid container spacing={1} sx={{
                  marginBottom: 1,
                  overflowX: 'auto',
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: 1,
                }}>
                  <Item
                    key={profile?.context}
                    sx={{
                      backgroundColor: DEFAULT_THEME_COLOR,
                      color: 'white',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 40,
                      width: 150,
                      marginTop: 1,
                      marginLeft: 1,
                    }}
                  >
                    {getFreakIcon(profile?.context)}
                  </Item>
                </Grid>

                <div style={{ fontSize: 13, marginBottom: 2, }}>{translation.profile.freakFeeds}</div>
                <Grid container spacing={1} sx={{
                  marginBottom: 1,
                  overflowX: 'auto',
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: 1,
                }}>

                  {
                    profile.youtubeFeedsCount
                      ? <Grid item xs={4}
                              onClick={() => { navigate(`/user-feeds/@${profile.username}/youtube`) }}
                        >
                        <Card sx={{
                          display: 'flex',
                          boxShadow: 'none',
                          minWidth: 200,
                        }}>
                          <CardMedia sx={{
                            display: 'flex',
                            alignItem: 'center',
                          }}>
                            <YouTubeIcon sx={{
                              color: '#FF0000',
                              width: 50,
                              height: 50,
                              maxWidth: 75,
                              maxHeight: 75,
                              marginLeft: 1,
                              borderRadius: 5,
                              margin: 1,
                            }}/>
                          </CardMedia>
                          <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <CardContent sx={{flex: '1 0 auto'}}>
                              <div
                                style={{
                                  fontSize: 13,
                                  fontWeight: 'bold'
                                }}
                              >Youtube
                              </div>
                              <div
                                style={{
                                  fontSize: 13,
                                }}
                              >{profile.youtubeFeedsCount} {profile.youtubeFeedsCount > 1 ? 'feeds' : 'feed'}</div>
                            </CardContent>
                          </Box>
                        </Card>
                      </Grid>
                      : null
                  }

                  {
                    profile.instagramFeedsCount
                      ? <Grid item xs={4}
                              onClick={() => { navigate(`/user-feeds/@${profile.username}/instagram`) }}
                        >
                        <Card sx={{
                          display: 'flex',
                          boxShadow: 'none',
                          minWidth: 200,
                        }}>
                          <CardMedia sx={{
                            display: 'flex',
                            alignItem: 'center'
                          }}>
                            <InstagramIcon sx={{
                              color: 'rgb(30, 48, 80)',
                              width: 50,
                              height: 50,
                              maxWidth: 75,
                              maxHeight: 75,
                              borderRadius: 5,
                              margin: 1,
                            }}/>
                          </CardMedia>
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}>
                            <CardContent sx={{
                              flex: '1 0 auto'
                            }}>
                              <div
                                style={{
                                  fontSize: 13,
                                  fontWeight: 'bold'
                                }}
                              >Instagram
                              </div>
                              <div
                                style={{
                                  fontSize: 13,
                                }}
                              >{profile.instagramFeedsCount} {profile.instagramFeedsCount > 1 ? 'feeds' : 'feed'}</div>
                            </CardContent>
                          </Box>
                        </Card>
                      </Grid>
                      : null
                  }

                  {
                    profile.facebookFeedsCount
                      ? <Grid item xs={4}
                          onClick={() => { navigate(`/user-feeds/@${profile.username}/facebook`) }}
                        >
                        <Card sx={{
                          display: 'flex',
                          boxShadow: 'none',
                          minWidth: 200,
                        }}>
                          <CardMedia sx={{
                            display: 'flex',
                            alignItem: 'center',
                          }}>
                            <FacebookIcon sx={{
                              color: '#1877F2',
                              width: 50,
                              height: 50,
                              maxWidth: 75,
                              maxHeight: 75,
                              borderRadius: 5,
                              margin: 1,
                            }}/>
                          </CardMedia>
                          <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <CardContent sx={{flex: '1 0 auto'}}>
                              <div
                                style={{
                                  fontSize: 13,
                                  fontWeight: 'bold'
                                }}
                              >Facebook
                              </div>
                              <div
                                style={{
                                  fontSize: 13,
                                }}
                              >{profile.facebookFeedsCount} {profile.facebookFeedsCount > 1 ? 'feeds' : 'feed'}</div>
                            </CardContent>
                          </Box>
                        </Card>
                      </Grid>
                      : null
                  }
                </Grid>

                <div style={{ fontSize: 13, marginBottom: 2, }}>{translation.profile.freakHubs}</div>
                <Grid container spacing={1} sx={{
                  marginBottom: 1,
                  overflowX: 'auto',
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: 1,
                  padding: 1,
                }}>
                  {
                    profile?.freakHubs
                      ? profile?.freakHubs.map(freakHub => (

                          <Card sx={{
                            display: 'flex',
                            boxShadow: 'none',
                            minWidth: 200,
                          }}
                                onClick={() => { navigate(`/freak-hub/${freakHub.slug}`) }}>
                            <CardMedia sx={{
                              display: 'flex',
                              alignItem: 'center',
                            }}>
                              <Avatar
                                sx={{
                                  width: 50,
                                  height: 50,
                                  borderRadius: 5,
                                  margin: 1,
                                }}
                                alt={freakHub.id}
                                src={freakHub?.imageUrl}
                              />
                            </CardMedia>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                              <CardContent sx={{ flex: '1 0 auto'}}>
                                <div
                                  style={{
                                    fontSize: 13,
                                    fontWeight: 'bold',
                                  }}
                                >{freakHub.name}
                                </div>
                                <div
                                  style={{
                                    fontSize: 12,
                                    maxWidth: '100px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >{freakHub.description}</div>
                              </CardContent>
                            </Box>
                          </Card>
                      ))
                      : <div>-</div>
                  }
                </Grid>

                <br/>
                <ButtonGroup
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                  size={'small'}
                  variant="outlined"
                  orientation="vertical"
                >
                  <EditProfileSettings isUser={isUser} />
                  <PricesHistory isUser={isUser} />
                  <TopFeeds isUser={isUser} />
                </ButtonGroup>

                <br/>
                <FollowUserButton
                  isUser={isUser}
                  user={profile}
                  follow={props.onFollow}
                  unfollow={props.onUnfollow}
                />
                <br/>
                <Logout isUser={isUser} onLogout={props.onClickLogout} />
              </div>
            </div>
          </div>
        </Box>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-10 offset-md-1">
              <FreakList
                tab={props.tab}
                profileUsername={username}
                pager={props.pager}
                freaks={props.freaks}
                loading={props.loading}
                freaksCount={props.freaksCount}
                currentPage={props.currentPage}
                context={context}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state.freakList,
  currentUser: state.common.currentUser,
  profile: state.profile,
  context: state.common.context,
  device: state.common.device,
});

const mapDispatchToProps = dispatch => ({
  onClickLogout: () => dispatch({type: LOGOUT}),
  onFollow: username => dispatch({
    type: FOLLOW_USER,
    payload: agent.Profile.follow(username)
  }),
  onLoad: payload => dispatch({type: PROFILE_PAGE_LOADED, payload}),
  onUnfollow: username => dispatch({
    type: UNFOLLOW_USER,
    payload: agent.Profile.unfollow(username)
  }),
  onUnload: () => dispatch({type: PROFILE_PAGE_UNLOADED})
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
export {Profile, mapStateToProps};
