import React, { useEffect, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import agent from '../../agent';
import Box from "@mui/material/Box";
import useTranslation from "../../customHooks/translations";
import {IconButton} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CommonLoader from "../Loaders/CommonLoader";

const FreakSelect = (props) => {
  const translation = useTranslation();
  const [data, setData] = useState([]);
  const [isFetched, setIsFetched] = useState(false);

  // load users
  useEffect(() => {
    setIsFetched(false);
    const fetchData = async () => {
      const results = await agent.Profile.getAllForParticipants();

      if (!isFetched) {
        setData(results.profiles || []);
        setIsFetched(true);
      }
    };

    fetchData();
  }, []);

  return (
    <Stack spacing={3} sx={{
      width: '100%',
    }}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={data}
        value={props.value}
        loading={!isFetched}
        loadingText={
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
            <CommonLoader />
          </Box>
        }
        getOptionLabel={(option) => option.username}
        onChange={(event, value) => {
          props.onSelect(value)
        }}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <img
              // loading="lazy"
              width="20"
              srcSet={option.imageUrl || '/logo-circle.png'}
              src={option.imageUrl || './logo-circle.png'}
              alt=""
            />
            {option.username}
          </Box>
        )}
        renderInput={(params) => {
          return (
            <TextField
              sx={{
                width: '100%',
              }}
              {
                ...params
              }
              endAdornment={
                <IconButton>
                  <SearchIcon />
                </IconButton>
              }
              size={'small'}
              variant="outlined"
              label={translation.freakSelect.placeholders.selectParticipants}
              placeholder={translation.freakSelect.placeholders.searchParticipants}
            />
          )
        }}
      />
    </Stack>
  );
}

export default FreakSelect;
