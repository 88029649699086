import React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ButtonGroup from "@mui/material/ButtonGroup";
import useTranslation from "../../customHooks/translations";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import Avatar from "@mui/material/Avatar";
import {Link, useNavigate} from "react-router-dom";

const TooManyRequestModal = ({ isOpen, onClose, onConfirm, isIncognito }) => {
  const translation = useTranslation()
  const navigate = useNavigate();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        textAlign: 'center',
      }}
      >
        <Typography id="modal-modal-title" component="h3">
          {
            isIncognito
              ? `${translation.modals.tooManyRequests.niceTry}`
              : `${translation.modals.tooManyRequests.youHaveReachedYourLimit}`
          }
        </Typography>

        <Avatar style={{ width: 200, height: 200, marginLeft: 'auto', marginRight: 'auto' }} src={'./logo-circle.png'} />

        <ButtonGroup
          sx={{
            display: 'flex',
            marginTop: 1,
          }}
          orientation="horizontal"
        >
          <Button
            onClick={onConfirm}
            variant="contained"
            size="md"
            style={{
              width: '100%',
              backgroundColor: DEFAULT_THEME_COLOR,
              color: 'white',
              alignSelf: 'flex-end',
              'z-index': '0',
              borderRadius: 4,
              marginRight: 1,
            }}
            onClick={() => navigate('/register')}
          >
            {translation.modals.tooManyRequests.register}
          </Button>

        </ButtonGroup>
        <div style={{ marginTop: 10 }}>
          <Link
            style={{
              textDecoration: 'underline'
            }}
            to={'https://newsletter.freak-land.com'}
            target="_blank"
          >
            {translation.modals.tooManyRequests.newsletter}
          </Link>

          <a href="https://buymeacoffee.com/pejaneczekr" target="_blank">
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-orange.png"
              alt="Buy Me a Coffee"
              style={{
                height: 100,
                width: 300,
                border: 'none',
                display: 'block',
                margin: 'auto',
                padding: 10
              }}
            />
          </a>
        </div>
      </Box>
    </Modal>
  );
};

export default TooManyRequestModal;
