import React, {useEffect, useRef} from 'react';
import {connect} from "react-redux";

import 'leaflet/dist/leaflet.css';
import {MapContainer, Marker, Polyline, Popup, TileLayer, useMap} from "react-leaflet";
import L from 'leaflet';
import {Icon} from 'leaflet'
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTranslation from "../../customHooks/translations";

const getContextTrackingComponent = (
  translations,
  context,
  distance= 0.0,
  altitude = 0,
  maxSpeed = 0.0,
  movingTime = 0.0,
  maxAltitude = 0,
  avgSpeed = 0,
) => {
  return (
    <>
      <Grid container sx={{
        marginTop: 3,
        marginBottom: 3,
      }}>
        <Grid item xs={4} sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'left'
        }}>
          <Grid container>
            <Grid item xs={12} sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'left'
            }}>
              <Typography sx={{
                marginLeft: 1,
                fontSize: 13,
                color: 'rgb(82, 88, 102)'
              }}>
                {translations.freakLocalizationTracker.distance}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{marginLeft: 1, color: 'black'}}>
                {distance} km
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
          <Grid container>
            <Grid item xs={12} sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'left'
            }}>
              <Typography sx={{
                fontSize: 13,
                color: 'rgb(82, 88, 102)'
              }}>
                {translations.freakLocalizationTracker.altitude}
              </Typography>
            </Grid>
            <Grid item xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left'
                  }}>
              <Typography variant="h6" sx={{color: 'black'}}>
                {altitude} m
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'right'
              }}>
          <Grid container>
            <Grid item xs={12} sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'left'
            }}>
              <Typography sx={{
                marginLeft: 1,
                fontSize: 13,
                color: 'rgb(82, 88, 102)'
              }}>
                {translations.freakLocalizationTracker.maxSpeed}
              </Typography>
            </Grid>
            <Grid item xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'right'
                  }}>
              <Typography variant="h6" sx={{marginLeft: 1, color: 'black'}}>
                {maxSpeed} km/h
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{
        marginTop: 3,
        marginBottom: 3,
      }}>
        <Grid item xs={4} sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'left'
        }}>
          <Grid container>
            <Grid item xs={12} sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'left'
            }}>
              <Typography sx={{
                marginLeft: 1,
                fontSize: 13,
                color: 'rgb(82, 88, 102)'
              }}>
                {translations.freakLocalizationTracker.movingTime}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{marginLeft: 1, color: 'black'}}>
                {movingTime}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
          <Grid container>
            <Grid item xs={12} sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'left'
            }}>
              <Typography sx={{
                fontSize: 13,
                color: 'rgb(82, 88, 102)'
              }}>
                {translations.freakLocalizationTracker.maxAltitude}
              </Typography>
            </Grid>
            <Grid item xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'left'
                  }}>
              <Typography variant="h6" sx={{color: 'black'}}>
                {maxAltitude} m
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'right'
              }}>
          <Grid container>
            <Grid item xs={12} sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'left'
            }}>
              <Typography sx={{
                marginLeft: 1,
                fontSize: 13,
                color: 'rgb(82, 88, 102)'
              }}>
                {translations.freakLocalizationTracker.avgSpeed}
              </Typography>
            </Grid>
            <Grid item xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'right'
                  }}>
              <Typography variant="h6" sx={{marginLeft: 1, color: 'black'}}>
                {avgSpeed} km/h
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
};

const FreakLocalizationTracker = (props) => {
  const translations = useTranslation();
  const mapRef = useRef(null);

  const context = props.context || 'wof';
  const defaultPosition = [50.258598, 19.020420];
  const position = props?.position || defaultPosition;
  const defaultZoom = 16;
  const zoom = props.zoom || defaultZoom;

  // Get the start and end positions
  const startPosition = props?.routeCoordinates[0];
  const endPosition = props?.routeCoordinates[props?.routeCoordinates.length - 1];

  // Function to adjust the map to fit the route
  const FitMapToRoute = ({positions}) => {
    const map = useMap();

    useEffect(() => {
      if (positions && positions.length > 0) {
        // Get the bounds based on the start and end points
        const bounds = L.latLngBounds(positions);
        map.fitBounds(bounds); // Fit the map to the bounds
      }
    }, [map, positions]);

    return null; // This component does not render anything
  };

  // Function to center the map on the user's current location with higher zoom
  const FitMapToUser = ({position, zoomLevel = 18}) => {
    const map = useMap();

    useEffect(() => {
      if (position && position.length) {
        // Create a LatLng object for the user's current position
        const userLatLng = L.latLng(position[0], position[1]);

        // map.flyTo(userLatLng, zoomLevel, {
        //   animate: true,
        //   duration: 1.5,
        // });
        map.setView(userLatLng, zoomLevel);
      }
    }, [map, position, zoomLevel]);

    return null; // This component does not render anything
  };

  // Define custom start and end icons
  const startIcon = L.icon({
    iconUrl: '/icons/pin.png', // Path to the start icon image
    iconSize: [32, 32], // Size of the icon
    iconAnchor: [16, 32], // Anchor point of the icon (bottom center)
  });

  const endIcon = L.icon({
    iconUrl: '/icons/pin.png', // Path to the end icon image
    iconSize: [32, 32], // Size of the icon
    iconAnchor: [16, 32], // Anchor point of the icon (bottom center)
  });

  return (
    position
      ? <>
        <MapContainer
          center={position}
          zoom={zoom}
          style={{height: '45vh', width: '100wh'}}
          ref={mapRef}
          attributionControl={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
          />
          {
            position
              ?
              <Marker
                position={position}
                icon={new Icon({
                    iconUrl: '/icons/marker-public-icon.png',
                    iconSize: [32, 32],
                    iconAnchor: [16, 32]
                  }
                )}
              >
              </Marker>
              : null
          }

          {
            props?.routeCoordinates
              ? <Polyline
                pathOptions={{
                  color: DEFAULT_THEME_COLOR,
                  weight: 4,
                  opacity: 0.9,
                  lineJoin: 'round',
                  lineCap: 'round'
                }}
                positions={props.routeCoordinates}
              />
              : null
          }

          {
            startPosition
              ? <Marker position={startPosition} icon={startIcon}/>
              : null
          }

          {
            !props.isRunning && endPosition
              ? <Marker position={endPosition} icon={endIcon}/>
              : null
          }

          {
            !props.isRunning && props?.routeCoordinates
              ? <FitMapToRoute positions={props.routeCoordinates}/>
              : null
          }
          {
            props.isRunning && props?.routeCoordinates
             ? <FitMapToUser position={position}/>
             : null
          }
        </MapContainer>

        {/*{getContextTrackingComponent(translations, context, props.distance)}*/}
      </>
      : null
  )
};

const mapStateToProps = state => ({
  context: state.common.context,
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FreakLocalizationTracker);
