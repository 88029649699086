import React, {useEffect, useState} from 'react';
import agent from '../agent';
import {connect} from 'react-redux';
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import {CometChatUIKit, UIKitSettingsBuilder, CometChatLocalize} from '@cometchat/chat-uikit-react';
import {CometChat} from "@cometchat/chat-sdk-javascript";

import {APP_LOAD} from '../constants/actionTypes';
import Article from '../components/Article';
import Editor from '../components/Editor';
import Login from '../components/Login';
import Profile from '../components/Profile';
import ProfileFavorites from '../components/ProfileFavorites';
import Register from '../components/Register';
import ResetPassword from '../components/ResetPassword';
import SetupPassword from '../components/SetupPassword';
import Settings from '../components/Settings';
import Freak from '../components/Freak';
import FreakEditor from '../components/Freak/FreakEditor';
import FreakJoin from './Freak/FreakJoin';
import FreakDetails from './Freak/FreakDetails';
import Notification from "./Notification";
import CometChatComponent from "./CometChat";

import FixedBottomNavigation from './BottomNavigation';
import BackdropWrapper from './BackdropWrapper';
import FreakPlaces from "./Freak/FreakPlaces";
import FreakCampaigns from "./Freak/FreakCampaigns";
import FreakPlacesEditor from "./Freak/FreakPlacesEditor";

import {useLanguageContext} from "../contexts/LanguageContext";
import ErrorBoundary from "./ErrorBoundary";
import FreakTrackerView from "./Freak/FreakTrackerView";
import {Snackbar} from '@mui/material';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useTranslation from "../customHooks/translations";
import {DEFAULT_THEME_COLOR} from "../constants/theme";
import {getLanguageByBrowser} from "../utils/languageUtils";
import EmailConfirmedView from "./EmailConfirmed/EmailConfirmedView";
import ReportAbuseView from "./ReportAbuse/ReportAbuseView";
import TopFreaksView from "./TopFreaks/TopFreaksView";
import FollowingView from "./Followers/FollowingView";
import FollowedByView from "./Followers/FollowedByView";
import FreakCampaignsEditor from "./Freak/FreakCampaignsEditor";
import FreakCampaignDetails from "./Freak/FreakCampaignDetails";
import FreakDeletedView from "./Freak/FreakDeletedView";
import FreakUnauthorizedView from "./Freak/FreakUnauthorizedView";
import UsersListView from "./UsersList/UsersListView";
import Error401View from "./ErrorsView/Error401View";
import Error404View from "./ErrorsView/Error404View";
import ShortsCreatorComponent from "./FreaksCreator/ShortsCreatorComponent";
import TermsOfService from "./GDPR/TermsOfService";
import PrivacyPolicy from "./GDPR/PrivacyPolicy";
import LeftNavigationComponent from "./LeftNavigation/LeftNavigationComponent";
import ContextSelectViewComponent from "./ContextSelectView/ContextSelectViewComponent";
import FeedsView from "./Feeds/FeedsView";
import { useAuth0 } from "@auth0/auth0-react";
import ShakeItView from "./ShakeIt/ShakeItView";
import ShakePricesHistoryView from "./ShakePricesHistory/ShakePricesHistoryView";
import TopFeedsView from "./TopFeeds/TopFeedsView";
import FreakHubView from "./FreakHub/FreakHubView";
import FreakHubDetails from "./FreakHub/FreakHubDetails";
import FreakHubEditor from "./FreakHub/FreakHubEditor";
import FreakHubUsersView from "./FreakHub/FreakHubUsersView";
import FreakPlacesRestricted from "./Freak/FreakPlacesRestricted";
import {RESTRICTED_PLACES_VIEW_PATH} from "../constants/restrictedViews";

const getDevice = (isMobile, isTablet, isDesktop) => {
  let device = '';
  if (isMobile) {
    device = 'mobile';
  } else if (isTablet) {
    device = 'tablet';
  } else if (isDesktop) {
    device = 'desktop';
  }
  return device;
};

const initChatUIKit = config => {
  const COMETCHAT_CONSTANTS = {
    APP_ID: config.appId,
    REGION: config.region,
    AUTH_KEY: config.authKey,
  };

  const UIKitSettings = new UIKitSettingsBuilder()
  .setAppId(COMETCHAT_CONSTANTS.APP_ID)
  .setRegion(COMETCHAT_CONSTANTS.REGION)
  .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
  .subscribePresenceForFriends()
  .build();

  CometChatUIKit.init(UIKitSettings).then(() => {
    console.log('Chat initialization completed successfully');
  }).catch(console.log);
};

const initChatSDK = config => {
  const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(config.REGION)
  .autoEstablishSocketConnection(true)
  .build();
  CometChat.init(config.APP_ID, appSetting).then(
    () => {
      console.log("Initialization completed successfully");
    }, error => {
      console.log("Initialization failed with error:", error);
    }
  );
};

const authorizeChatUser = authTokenResponse => {
  if (authTokenResponse?.authToken) {
    CometChatUIKit.getLoggedinUser().then(user => {
      if (!user) {
        CometChatUIKit.loginWithAuthToken(authTokenResponse.authToken).then(user => {
        }).catch(console.log('loginWithAuthToken : error'));
      } else {
      }
    }).catch(error => console.log('getLoggedInUser : error: ', {error}));
  }
};

const updateChatUserAvatar = async (uid, userImageUrl, config) => {
  try {
    const user = new CometChat.User(uid);
    user.setAvatar(userImageUrl);
    await CometChat.updateUser(user, config.authKey);
  } catch (error) {
    console.log('updateChatUserAvatar : error', {error});
  }
};

function SlideTransition(props) {
  return <Slide {...props} direction="down"/>;
};

const initCometChat = async (token, translation, currentUser) => {
  if (token) {
    try {
      const chatConfig = await agent.Chat.getConfig();
      initChatUIKit(chatConfig);
      initChatSDK(chatConfig);

      const chatAuthToken = await agent.Chat.getToken();
      authorizeChatUser(chatAuthToken);
      if (currentUser) {
        await updateChatUserAvatar(
          currentUser.username,
          currentUser.imageUrl,
          chatConfig
        );
      }

      const lang = getLanguageByBrowser();
      CometChatLocalize.init(lang, {
        [lang]: {
          CHATS: translation.cometChat.CHATS,
          DELETE_CONVERSATION: translation.cometChat.DELETE_CONVERSATION,
          WOULD__YOU_LIKE_TO_DELETE_THIS_CONVERSATION: translation.cometChat.WOULD__YOU_LIKE_TO_DELETE_THIS_CONVERSATION,
          USERS: translation.cometChat.USERS,
        },
      });
    } catch (error) {
      console.log('initCometChat : error', {error});
    }
  }
};

const initNotification = async (setSnackbar, translation) => {
  setSnackbar({
    open: true,
    message: translation.notifications.settings,
    severity: 'info',
    hideOpenButton: false,
    link: '/settings'
  });
};

const App = (props) => {
  const translation = useTranslation();
  const location = useLocation();
  const isMobile = useMediaQuery({maxWidth: 767});
  const isTablet = useMediaQuery({minWidth: 768, maxWidth: 991});
  const isDesktop = useMediaQuery({minWidth: 992});
  const device = getDevice(isMobile, isTablet, isDesktop);
  const navigate = useNavigate();
  const {handleChangeLanguage} = useLanguageContext();
  const context = props?.context || props?.currentUser?.context || 'wof';
  const [snackbar, setSnackbar] = useState({open: false, message: '', severity: 'info', hideOpenButton: false});
  const { isAuthenticated, getAccessTokenSilently} = useAuth0();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.redirectTo) {
      navigate(props.redirectTo);
    }
  }, [props.redirectTo]);

  useEffect(() => {
    const initApp = async () => {
      if (location?.pathname !== RESTRICTED_PLACES_VIEW_PATH) {
        try {
          const token = await getAccessTokenSilently();
          if (token) {
            agent.setToken(token);
          }
          const user = await agent.Auth.current();
          props.onLoad(token ? user : null, token, device, context);

          if (token && !window.cordova) {
            initNotification(setSnackbar, translation);
          }

          initCometChat(token, translation, props.currentUser);

          handleChangeLanguage(props.currentUser?.language || getLanguageByBrowser());

          if (!props.newThemeMode && props.currentUser?.themeMode) {
            props.handleTheme(props.currentUser?.themeMode);
          } else if (props.newThemeMode && props.newThemeMode !== props.currentUser?.themeMode) {
            props.handleTheme(props.newThemeMode);
          }
        } catch (error) {
          navigate('/login');
        } finally {
          setLoading(false);
        }
      }
    };

    initApp();
  }, [])

  const handleClose = () => {
    setSnackbar({...snackbar, open: false});
  };

  return (
    <ErrorBoundary>
      {
        <>
          <Routes>
            <Route path="/" element={<Navigate to="/freak"/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/reset-password" element={<ResetPassword/>}/>
            <Route path="/setup-password" element={<SetupPassword/>}/>
            <Route path="/editor/:slug" element={<Editor/>}/>
            <Route path="/editor" element={<Editor/>}/>
            <Route path="/article/:id" element={<Article/>}/>
            <Route path="/settings" element={<Settings handleTheme={props.handleTheme}/>}/>
            <Route path="/notifications" element={<Notification/>}/>
            <Route path="/messages" element={<CometChatComponent/>}/>
            <Route path="/:username/favorites" element={<ProfileFavorites/>}/>
            <Route path="/:username" element={<Profile/>}/>
            <Route path="/freak" element={<Freak context={context} initializing={loading}/>}/>
            <Route path="/freak/:id" element={<FreakDetails context={context}/>}/>
            <Route path="/freak-editor/:slug" element={<FreakEditor/>}/>
            <Route path="/freak-editor" element={<FreakEditor context={context}/>}/>
            <Route path="/freak-join/:slug" element={<FreakJoin/>}/>
            <Route path="/places" element={<FreakPlaces context={context}/>}/>
            <Route path="/places-restricted" element={<FreakPlacesRestricted context={context}/>}/>
            <Route path="/places-editor/:slug" element={<FreakPlacesEditor context={context}/>}/>
            <Route path="/places-editor" element={<FreakPlacesEditor context={context}/>}/>
            <Route path="/campaigns" element={<FreakCampaigns context={context}/>}/>
            <Route path="/campaigns/:id" element={<FreakCampaignDetails/>}/>
            <Route path="/campaigns-editor" element={<FreakCampaignsEditor context={context}/>}/>
            <Route path="/campaigns-editor/:slug" element={<FreakCampaignsEditor context={context}/>}/>
            <Route path="/record" element={<FreakTrackerView/>}/>
            <Route path="/email-confirmed" element={<EmailConfirmedView/>}/>
            <Route path="/report-abuse/:slug" element={<ReportAbuseView/>}/>
            <Route path="/top-freaks" element={<TopFreaksView/>}/>
            <Route path="/following/:username" element={<FollowingView/>}/>
            <Route path="/followed-by/:username" element={<FollowedByView/>}/>
            <Route path="/freak-deleted" element={<FreakDeletedView/>}/>
            <Route path="/freak-unathorized" element={<FreakUnauthorizedView/>}/>
            <Route path="/users-list" element={<UsersListView/>}/>
            <Route path="/401" element={<Error401View/>}/>
            <Route path="/404" element={<Error404View/>}/>
            <Route path="/create-shorts" element={<ShortsCreatorComponent/>}/>
            <Route path="/terms-of-service" element={<TermsOfService/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
            <Route path="/user-feeds/:username/:social" element={<FeedsView/>}/>
            <Route path="/context-change" element={<ContextSelectViewComponent/>}/>
            <Route path="/shake-it" element={<ShakeItView/>}/>
            <Route path="/prices-history" element={<ShakePricesHistoryView/>}/>
            <Route path="/top-feeds" element={<TopFeedsView/>}/>
            <Route path="/freak-hub" element={<FreakHubView context={context}/>}/>
            <Route path="/freak-hub/:slug" element={<FreakHubDetails context={context}/>}/>
            <Route path="/freak-hub/:slug/users" element={<FreakHubUsersView context={context}/>}/>
            <Route path="/freak-hub-editor" element={<FreakHubEditor context={context}/>}/>
            <Route path="/freak-hub-editor/:slug" element={<FreakHubEditor context={context}/>}/>
            <Route path="/*" element={<Login/>}/>
          </Routes>

          {isDesktop ? <LeftNavigationComponent/> : <FixedBottomNavigation />}

          <Snackbar
            open={snackbar.open}
            variant={'solid'}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            sx={{
              marginTop: 7,
            }}
            TransitionComponent={SlideTransition}
            message={snackbar.message}
            action={
              <React.Fragment>
                {
                  snackbar.hideOpenButton
                    ? null
                    : <Button
                      style={{
                        backgroundColor: DEFAULT_THEME_COLOR,
                        color: 'white'
                      }}
                      sx={{
                        outline: 'none',
                        '&:focus': {
                          outline: 'none'
                        },
                        '&:hover': {
                          color: DEFAULT_THEME_COLOR,
                          borderColor: DEFAULT_THEME_COLOR,
                        },
                      }}
                      size="small"
                      onClick={() => {
                        snackbar.link ? navigate(snackbar.link) : navigate('/notifications')
                      }}>
                      {translation.snackbar.open}
                    </Button>
                }
                <IconButton
                  aria-label="close"
                  style={{
                    color: DEFAULT_THEME_COLOR
                  }}
                  sx={{p: 0.5}}
                  onClick={handleClose}
                >
                  <CloseIcon/>
                </IconButton>
              </React.Fragment>
            }
          >
          </Snackbar>
        </>
      }
      <BackdropWrapper/>
    </ErrorBoundary>
  );
};

const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo,
    device: state.common.device,
    context: state.common.context,
    newThemeMode: state.common.newThemeMode,
  }
};

const mapDispatchToProps = dispatch => ({
  onLoad: (payload, token, device, context) => {
    dispatch({type: APP_LOAD, payload, token, skipTracking: true, device})
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
