import React from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import "./styles.css";
import Avatar from "@mui/material/Avatar";

const SearchFreakResultItem = (props) => {
  const navigate = useNavigate();
  const itemData = props.item;

  return (
    <div style={{
      backgroundColor: 'black',
      width: '95%',
      height: 150,
      marginTop: 5,
    }}>
      <Card
        sx={{ display: 'flex' }}
        onClick={() => navigate(`/freak/${itemData.slug}`)}
      >
        <CardMedia
          component="img"
          sx={{
            width: 150,
            height: 150,
            objectFit: 'cover',
          }}
          image={itemData?.imageUrl || '/logo.png'}
          alt={itemData.id}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography
              component="div"
              variant="h7"
              sx={{
                maxHeight: '2.5em',
                lineHeight: '1.25em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {itemData.title}
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                fontSize: 10,
                color: 'text.secondary',
                maxHeight: '3em',
                lineHeight: '1.5em',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {itemData.body}
            </Typography>
          </CardContent>
          <Avatar
            sx={{
              width: 20,
              height: 20,
              borderRadius: 5,
              margin: 1,
            }}
            alt={itemData.id}
            src={'./algolia-logo.png'}
          />
        </Box>
      </Card>
    </div>
  )
};

export default SearchFreakResultItem;
