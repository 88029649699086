import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';

import agent from '../../agent';
import {FREAK_FAVORITED, FREAK_UNFAVORITED} from '../../constants/actionTypes';
import videojs from 'video.js';
import ButtonGroup from '@mui/material/ButtonGroup';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  COLOR_FINISHED,
  COLOR_IN_PROGRESS,
  COLOR_PLANNED,
  MESSAGE_IN_PROGRESS,
  STATUS_FINISHED,
  STATUS_IN_PROGRESS,
  STATUS_PLANNED,
  STATUS_CANCELED,
  COLOR_CANCELED,
} from '../../constants/commonConstants';
import {
  isFreakParticipant,
  isFreakParticipantDeclined,
  isFreakOwner,
  calculateDistanceToFreak,
  getLatLon,
  getFreakIcon,
  isFeed,
} from '../../utils/freakUtils';
import {
  getVisibilityLevel,
  getVisibilityLevelColor,
} from '../../utils/commonUtils';
import { formatDateAgo, formatDate } from '../../utils/dateUtils';
import {DEFAULT_BORDER_COLOR, DEFAULT_THEME_COLOR, FEED_DAILY_COLOR} from '../../constants/theme';
import Box from "@mui/material/Box";
import ReadMoreComponent from "../ReadMoreComponent";
import useTranslation from "../../customHooks/translations";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { getPreviewCarousel, getStatusMessage } from "../../utils/commonUtils";
import ListItemIcon from "@mui/material/ListItemIcon";
import FlagIcon from '@mui/icons-material/Flag';
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import LinkIcon from '@mui/icons-material/Link';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Tooltip from "@mui/material/Tooltip";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import IosShareIcon from '@mui/icons-material/IosShare';

const FAVORITED_CLASS = 'btn btn-sm btn-primary';
const NOT_FAVORITED_CLASS = 'btn btn-sm btn-outline-primary';

const getStatusButtonColor = freak => {
  if (!freak?.status) {
    return 'error';
  }
  switch (freak.status) {
    case STATUS_IN_PROGRESS:
      return COLOR_IN_PROGRESS;
    case STATUS_FINISHED:
      return COLOR_FINISHED;
    case STATUS_PLANNED:
      return COLOR_PLANNED;
    case STATUS_CANCELED:
      return COLOR_CANCELED;
    default:
      return COLOR_FINISHED;
  }
};

const getFreakTimeMessage = (freak, translation) => {
  if (!freak?.startDate) {
    return '';
  }

  switch (freak.status) {
    case STATUS_IN_PROGRESS:
      return translation.freakPreview.messages.alreadyRunning;
    case STATUS_FINISHED:
      return `${translation.freakPreview.messages.finishedAt}:  ${formatDate(freak.endDate)}`;
    case STATUS_PLANNED:
      return `${translation.freakPreview.messages.plannedTo}: ${formatDate(freak.startDate)}`;
  }
};

const getFreakLocationMessage = freak => {
  if (!freak?.map) {
    return '';
  }

  return `${freak.map.description}`;
};

const FreakPreview = props => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const playerRef = React.useRef(null);

  const [freak, setFreak] = useState(props.freak);

  const favoriteButtonClass = freak.favorited ?
    FAVORITED_CLASS :
    NOT_FAVORITED_CLASS;

  const [joinButtonMessage, setJoinButtonMessage] = useState(translation.freaks.operations.join);
  const [joinButtonDisabled, setJoinButtonDisabled] = useState(false);

  const [startButtonMessage, setStartButtonMessage] = useState(translation.freaks.operations.start);
  const [startButtonDisabled, setStartButtonDisabled] = useState(false);
  const [startButtonColor, setStartButtonColor] = useState(DEFAULT_THEME_COLOR);

  const [geolocation, setGeolocation] = useState(null);
  const [errorGeoLocation, setGeoErrorLocation] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipText, setTooltipText] = useState(translation.freakPreview.copy.copyLink);

  const open = Boolean(anchorEl);
  const handleDotsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDotsClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchGeoLocation = async () => {
      try {
        await getLatLon(setGeolocation, setGeoErrorLocation);
      } catch (error) {
        console.log(error)
      }
    };
    fetchGeoLocation();
  }, []);

  useEffect(() => {
    // set proper message when user is declined from freak
    if (freak && props.currentUser) {
      const participant = freak?.participants?.find(participant =>
        participant.username === props.currentUser?.username
      );
      if (participant) {
        if (participant.status === 'declined') {
          setJoinButtonMessage(translation.freaks.participate.declined);
        } else if (participant.status === 'pending' || participant.status === 'request_join') {
          setJoinButtonMessage(translation.freaks.participate.requestJoin);
        }
      }
    }

    // set proper message when freak is in progress
    if (freak && freak.status === STATUS_IN_PROGRESS) {
      setStartButtonMessage(MESSAGE_IN_PROGRESS);
      setStartButtonColor(COLOR_IN_PROGRESS);
    }
  }, []);

  const statusButtonMessage = getStatusMessage(freak, translation);
  const statusButtonColor = getStatusButtonColor(freak);

  const handleRequestJoinClick = freak => {
    agent.Freaks.join(freak.id);
    setJoinButtonMessage(translation.freaks.operations.requested);
    setJoinButtonDisabled(true);
  };
  const handleStartClick = freak => {
    agent.Freaks.start(freak.id);
    setStartButtonMessage(translation.freaks.status.inProgress);
    setStartButtonDisabled(true);
  };

  const handleFavoritedClick = ev => {
    ev.preventDefault();
    if (freak.favorited) {
      props.unfavorite(freak.slug);
      setFreak(prevFreak => ({
        ...prevFreak,
        favorited: false,
        favoritesCount: prevFreak.favoritesCount - 1
      }));
    } else {
      props.favorite(freak.slug);
      setFreak(prevFreak => ({
        ...prevFreak,
        favorited: true,
        favoritesCount: prevFreak.favoritesCount + 1
      }));
    }
  };

  const handleMessageClick = slug => {
    navigate(`/freak/${slug}`);
  };

  const getVideoPlayerOptions = freak => ({
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: freak.videos[0].videoUrl,
      type: 'video/mp4'
    }]
  });

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  const handleShare = async (freak) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: translation.freakPreview.share.checkoutThis,
          text: freak.title,
          url: `${window.location.origin}/login?redirectTo=/freak/${freak.slug}`,
        });
        console.log('Content shared successfully!');
      } catch (error) {
        console.error('Error sharing content:', error);
      }
    } else {
      console.log('Web Share API is not supported in your browser.');
    }
  };

  const handleCopyClick = async (freak) => {
    try {
      await navigator.clipboard.writeText(`${window.location.origin}/login?redirectTo=/freak/${freak.slug}`);
      setTooltipText(translation.freakPreview.copy.copied);
      setTimeout(() => setTooltipText(translation.freakPreview.copy.copyLink), 2000);
    } catch (err) {
      console.error('Failed to copy the link:', err);
      setTooltipText(translation.freakPreview.copy.failed);
    }
  };

  return (
    <div className="article-preview">
      <Grid container spacing={0} sx={{flexGrow: 1}}>
        <Grid item xs={12}>
          <div className="article-meta">
            <Grid container xs={12}>
              <Grid item xs={4} sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}>
                <Grid item>
                  <Link to={`/@${freak.author.username}`}>
                    <Avatar
                      alt={freak.author.username}
                      src={freak.author.imageUrl}
                    />
                  </Link>
                </Grid>
                <Grid item>
                  <div className="info">
                    <Link className="author" to={`/@${freak.author.username}`}>
                      {freak.author.username}
                    </Link>
                    <span className="date">
                      {formatDateAgo(freak.createdAt)}
                  </span>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {
                    props.freak?.context && !isFeed(props.freak)
                      ? <IconButton
                        sx={{
                          // color: 'black',
                          '&:focus': {
                            outline: 'none'
                          },
                        }}
                      >
                        {getFreakIcon(props.freak.context)}
                      </IconButton>
                      : null
                  }
                  <IconButton sx={{
                    color: 'white',
                    outline: 'none',
                    '&:focus': {
                      outline: 'none'
                    },
                  }} aria-label="back" onClick={handleDotsClick}>
                    <MoreVertIcon
                      sx={{
                        // color: 'black',
                        outline: 'none',
                      }}
                    />
                  </IconButton>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleDotsClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      onClick={() => navigate(`/report-abuse/${freak.slug}`)}
                    >
                      <ListItemIcon>
                        <FlagIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>{translation.reportAbuse.report}</ListItemText>
                    </MenuItem>
                  </Menu>
                </div>
              </Grid>
            </Grid>

            <div
              className="info"
              style={{
                // color: 'grey',
                fontSize: '.8rem',
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                marginTop: 10,
              }}
            >
              <Button
                variant="contained"
                style={{
                  marginTop: 0,
                  minWidth: 18,
                  height: 15,
                  fontSize: 10,
                  backgroundColor: getVisibilityLevelColor(freak),
                  color: 'white'
                }}
                disabled
              >
                {translation.freaks.visibility[getVisibilityLevel(freak)]}
              </Button>
              {!isFeed(freak) ? <br /> : null }
              {!isFeed(freak) ? getFreakLocationMessage(freak) : null } <br/>
              {!isFeed(freak) ? calculateDistanceToFreak(geolocation, freak, translation) : null } <br/>
              {!isFeed(freak) ? getFreakTimeMessage(freak, translation) : null }
            </div>
          </div>
          <Link to={`/freak/${freak.slug}`} className="preview-link">
            <h1></h1>
            <Typography variant="h6" component="h2" sx={{marginLeft: 2 }}>
              {freak.title}
            </Typography>
            <ReadMoreComponent text={freak.body} />

          </Link>

          <Box xs={12} sx={{ marginBottom: 2 }}>
            {
              getPreviewCarousel(freak)
            }
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        sx={{flexGrow: 1}}
      >
        <Grid item xs={7} sx={{
          padding: 1,
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'flex-end',
        }}>
          <ul className="tag-list">
            {
              freak?.tagList?.map(tag => {
                return (
                  <li className="tag-default tag-pill tag-outline" key={tag}>
                    {tag}
                  </li>
                )
              })
            }
          </ul>
          <ButtonGroup
            orientation="horizontal"
            variant="outlined"
          >
            <Avatar
              sx={{
                width: 25,
                height: 25,
                marginTop: 0.5,
              }}
              onClick={handleFavoritedClick}
              src={'./../heart.png'}
            />
            <span style={{ marginLeft: 2, fontSize: 15, color: 'red' }}>
                {freak.favoritesCount}
              </span>
            <QuestionAnswerIcon sx={{
              color: DEFAULT_THEME_COLOR,
              width: '100%',
              height: 'auto',
              marginLeft: 1,
              maxWidth: 25,
              maxHeight: 25,
              marginTop: 0.5,
            }}
             onClick={() => handleMessageClick(freak.slug)}
            />
            <span style={{
              marginLeft: 2,
              fontSize: 15,
              color: DEFAULT_THEME_COLOR,
            }}>
                {freak.commentsCount}
            </span>
            <Tooltip title={tooltipText} placement="top-start" arrow>
              <LinkIcon
                sx={{
                  color: DEFAULT_THEME_COLOR,
                  width: '100%',
                  height: 'auto',
                  marginLeft: 1,
                  maxWidth: 25,
                  maxHeight: 25,
                  marginTop: 0.5,
                }}
                onClick={() => handleCopyClick(freak)}
              />
            </Tooltip>
            {
              props.device === 'tablet' || props.device === 'mobile'
                ?
                <IosShareIcon
                  sx={{
                    color: DEFAULT_THEME_COLOR,
                    width: '100%',
                    height: 'auto',
                    marginLeft: 1,
                    maxWidth: 25,
                    maxHeight: 25,
                    marginTop: 0.5,
                  }}
                  onClick={() => handleShare(freak)}
                />
                : null
            }
          </ButtonGroup>
        </Grid>
        <Grid item xs={5} sx={{
          padding: 1,
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'flex-end',
        }}>
          <ButtonGroup
            orientation="vertical"
            variant="outlined"
          >
            {
              !isFeed(freak)
              ? <Button
                  size="md"
                  variant="contained"
                  disabled
                  style={{
                    width: 150,
                    backgroundColor: DEFAULT_THEME_COLOR,
                    color: 'white',
                    alignSelf: 'flex-end',
                    'z-index': '0',
                    borderRadius: 4,
                    marginBottom: 5,
                  }}
                >
                  {statusButtonMessage}
                </Button>
              : null
            }
            {
              (
                !isFeed(freak)
                && (freak?.status === STATUS_PLANNED || freak?.status === STATUS_IN_PROGRESS)
                && !isFreakParticipant(props.currentUser?.username, freak)
                && !isFreakParticipantDeclined(props.currentUser?.username, freak)
              )
                ? <Button
                    disabled={joinButtonDisabled}
                    onClick={() => handleRequestJoinClick(freak)}
                    style={{
                      color: DEFAULT_THEME_COLOR,
                      borderColor: DEFAULT_BORDER_COLOR,
                      width: 150,
                      alignSelf: 'flex-end',
                      'z-index': '0',
                      borderRadius: 4,
                      marginBottom: 5,
                    }}
                    variant="outlined"
                    startIcon={<PersonAddIcon/>}
                    >
                      {joinButtonMessage}
                  </Button>
                : null
            }
            {
              !isFeed(freak)
              && (freak?.status === STATUS_PLANNED && isFreakOwner(props.currentUser?.username, freak))
                ? <Button
                  disabled={startButtonDisabled}
                  onClick={() => handleStartClick(freak)}
                  style={{
                    color: startButtonColor,
                    borderColor: DEFAULT_THEME_COLOR,
                    width: 150,
                    alignSelf: 'flex-end',
                    'z-index': '0',
                    borderRadius: 4,
                  }}
                  variant="outlined"
                  >
                    {startButtonMessage}
                  </Button>
                : null
            }
            {
              !isFeed(freak)
              && isFreakParticipantDeclined(props.currentUser?.username, freak)
                ? <Button
                  disabled
                  style={{
                    color: DEFAULT_THEME_COLOR,
                    borderColor: DEFAULT_BORDER_COLOR,
                    width: 150,
                    alignSelf: 'flex-end',
                    'z-index': '0',
                    borderRadius: 4,
                  }}
                  variant="outlined"
                  startIcon={
                    isFreakParticipantDeclined(props.currentUser?.username, freak)
                      ? <PersonRemoveIcon />
                      : <PersonAddIcon/>
                  }
                >
                  {joinButtonMessage}
                </Button>
                : null
            }
          </ButtonGroup>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  device: state.common.device,
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
  favorite: slug => dispatch({
    type: FREAK_FAVORITED,
    payload: agent.Freaks.favorite(slug)
  }),
  unfavorite: slug => dispatch({
    type: FREAK_UNFAVORITED,
    payload: agent.Freaks.unfavorite(slug)
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(FreakPreview);
