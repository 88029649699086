import { connect } from "react-redux";
import ImageGallery from "react-image-gallery";
import Box from "@mui/material/Box";

import "react-image-gallery/styles/css/image-gallery.css";
import {BOTTOM_NAVIGATION_VISIBILITY, TOP_NAVIGATION_VISIBILITY} from "../../constants/actionTypes";
import "./style.css";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const ImageViewComponent = (props) => {
  const device = props.device;
  const mobileHeight = 240;
  const tabletHeight = 300;
  const desktopHeight = 500;
  const iframeHeight = device === 'mobile' ? mobileHeight : (device === 'tablet' ? tabletHeight : desktopHeight);

  const preparedImages = (props?.images || []).map(item => {
    const preparedImage = {
      original: item.imageUrl,
      thumbnail: item.imageUrl,
    };

    if (props.useOriginalHeight) {
      const originalHeight = device === 'mobile' ? mobileHeight : (device === 'tablet' ? tabletHeight : desktopHeight)
      Object.assign(preparedImage, {
        originalHeight: originalHeight,
      });
    }

    return preparedImage;
  });

  const preparedYoutubeVideos = (props.linksYoutube || []).map(item => {
    return {
      original: item.original,
      thumbnail: item.thumbnail,
      embedUrl: item.url,
      renderItem: (item) => {
        return (
          <Box sx={{ marginBottom: 10 }}>
            <div className="video-wrapper">
              <iframe
                width="100%"
                height={iframeHeight}
                src={item.embedUrl}
                style={{border: "none"}}
                allowFullScreen
              />
            </div>
          </Box>
        );
      }
    }
  });

  const preparedContent = [...preparedImages, ...preparedYoutubeVideos];
  let showFullScreenButton = preparedImages.length > 0;
  if (props.fullScreenButton === 'hide') {
    showFullScreenButton = false;
  }

  return (
    <ImageGallery
      items={preparedContent}
      lazyLoad={true}
      showBullets={preparedImages.length > 1}
      showThumbnails={false}
      showFullscreenButton={showFullScreenButton}
      showPlayButton={false}
      showNav={preparedImages.length > 1}
      renderLeftNav={(onClick, disabled) => {
        return (
          <button
            type="button"
            className="image-gallery-icon image-gallery-left-nav"
            disabled={disabled}
            onClick={onClick}
            aria-label="Previous Slide"
          >
            <ArrowLeftIcon />
          </button>
        )
      }}
      renderRightNav={(onClick, disabled) => {
        return (
          <button
            type="button"
            className="image-gallery-icon image-gallery-right-nav"
            disabled={disabled}
            onClick={onClick}
            aria-label="Next Slide"
          >
            <ArrowRightIcon />
          </button>
        );
      }}
      onScreenChange={(isFullScreen) => {
        props.setBottomNavigationVisible(!isFullScreen);
        props.setTopNavigationVisible(!isFullScreen);
      }}
    />
  );
};

const mapStateToProps = state => ({
  device: state.common.device,
});

const mapDispatchToProps = dispatch => ({
  setBottomNavigationVisible: isVisible => dispatch({
    type: BOTTOM_NAVIGATION_VISIBILITY,
    payload: isVisible
  }),
  setTopNavigationVisible: isVisible => dispatch({
    type: TOP_NAVIGATION_VISIBILITY,
    payload: isVisible
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewComponent);
