export const APP_LOAD = 'APP_LOAD';
export const IS_LOADING = 'IS_LOADING';
export const REDIRECT = 'REDIRECT';
export const ARTICLE_SUBMITTED = 'ARTICLE_SUBMITTED';
export const SETTINGS_SAVED = 'SETTINGS_SAVED';
export const THEME_MODE_CHANGE = 'THEME_MODE_CHANGE';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const ARTICLE_PAGE_LOADED = 'ARTICLE_PAGE_LOADED';
export const ARTICLE_PAGE_UNLOADED = 'ARTICLE_PAGE_UNLOADED';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const ARTICLE_FAVORITED = 'ARTICLE_FAVORITED';
export const ARTICLE_UNFAVORITED = 'ARTICLE_UNFAVORITED';
export const FREAK_FAVORITED = 'FREAK_FAVORITED';
export const FREAK_UNFAVORITED = 'FREAK_UNFAVORITED';
export const CAMPAIGN_FAVORITED = 'CAMPAIGN_FAVORITED';
export const CAMPAIGN_UNFAVORITED = 'CAMPAIGN_UNFAVORITED';
export const CAMPAIGN_SET_PAGE = 'CAMPAIGN_SET_PAGE';
export const SET_PAGE = 'SET_PAGE';
export const APPLY_TAG_FILTER = 'APPLY_TAG_FILTER';
export const CHANGE_TAB = 'CHANGE_TAB';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SETUP_PASSWORD = 'SETUP_PASSWORD';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const SETUP_PASSWORD_UNLOADED = 'SETUP_PASSWORD_UNLOADED';
export const RESET_PASSWORD_UNLOADED = 'RESET_PASSWORD_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const EDITOR_PAGE_LOADED = 'EDITOR_PAGE_LOADED';
export const EDITOR_PAGE_UNLOADED = 'EDITOR_PAGE_UNLOADED';
export const ADD_TAG = 'ADD_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const UPDATE_FIELD_FREAK_CAMPAIGN = 'UPDATE_FIELD_FREAK_CAMPAIGN';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const PROFILE_FAVORITES_PAGE_UNLOADED = 'PROFILE_FAVORITES_PAGE_UNLOADED';
export const PROFILE_FAVORITES_PAGE_LOADED = 'PROFILE_FAVORITES_PAGE_LOADED';
export const UPLOAD_USER_IMAGE = 'UPLOAD_USER_IMAGE';
export const UPLOAD_USER_BACKGROUND_IMAGE = 'UPLOAD_USER_BACKGROUND_IMAGE';
export const UPLOAD_FREAK_HUB_IMAGE = 'UPLOAD_FREAK_HUB_IMAGE';
export const UPLOAD_FREAK_HUB_BACKGROUND_IMAGE = 'UPLOAD_FREAK_HUB_BACKGROUND_IMAGE';
export const UPLOAD_ARTICLE_IMAGE = 'UPLOAD_ARTICLE_IMAGE';
export const UPLOAD_ARTICLE_VIDEO = 'UPLOAD_ARTICLE_VIDEO';
export const FREAKS_CHANGE_TAB = 'FREAKS_CHANGE_TAB';
export const FREAK_PAGE_LOADED = 'FREAK_PAGE_LOADED';
export const FREAK_PAGE_UNLOADED = 'FREAK_PAGE_UNLOADED';
export const DELETE_FREAK_HUB = 'DELETE_FREAK_HUB';
export const DELETE_FREAK = 'DELETE_FREAK';
export const CANCEL_FREAK = 'CANCEL_FREAK';
export const FINISH_FREAK = 'FINISH_FREAK';
export const LEAVE_FREAK = 'LEAVE_FREAK';
export const PUBLISH_FREAK = 'PUBLISH_FREAK';
export const DELETE_FREAK_PLACE = 'DELETE_FREAK_PLACE';
export const FREAK_EDITOR_PAGE_UNLOADED = 'FREAK_EDITOR_PAGE_UNLOADED';
export const FREAK_EDITOR_PAGE_LOADED = 'FREAK_EDITOR_PAGE_LOADED';
export const CAMPAIGN_PAGE_UNLOADED = 'CAMPAIGN_PAGE_UNLOADED';
export const CAMPAIGN_EDITOR_PAGE_UNLOADED = 'CAMPAIGN_EDITOR_PAGE_UNLOADED';
export const CAMPAIGN_PAGE_LOADED = 'CAMPAIGN_PAGE_LOADED';
export const CAMPAIGN_EDITOR_PAGE_LOADED = 'CAMPAIGN_EDITOR_PAGE_LOADED';
export const FREAK_SUBMITTED = 'FREAK_SUBMITTED';
export const FREAK_HUB_SUBMITTED = 'FREAK_HUB_SUBMITTED';
export const FREAK_PLACES_SUBMITTED = 'FREAK_PLACES_SUBMITTED';
export const CAMPAIGN_SUBMITTED = 'CAMPAIGN_SUBMITTED';
export const UPDATE_FIELD_FREAK_EDITOR = 'UPDATE_FIELD_FREAK_EDITOR';
export const UPLOAD_FREAK_IMAGE = 'UPLOAD_FREAK_IMAGE';
export const UPLOAD_FREAK_VIDEO = 'UPLOAD_FREAK_VIDEO';
export const FREAK_PRIVATE_CHANGE = 'FREAK_PRIVATE_CHANGE';
export const FREAKS_PAGE_LOADED = 'FREAKS_PAGE_LOADED';
export const FREAKS_PAGE_UNLOADED = 'FREAKS_PAGE_UNLOADED';
export const FREAKS_SET_PAGE = 'FREAKS_SET_PAGE';
export const NOTIFICATION_PAGE_LOADED = 'NOTIFICATION_PAGE_LOADED';
export const NOTIFICATION_PAGE_UNLOADED = 'NOTIFICATION_PAGE_UNLOADED';
export const NOTIFICATION_SET_PAGE = 'NOTIFICATION_SET_PAGE';
export const NOTIFICATION_OPERATION = 'NOTIFICATION_OPERATION';
export const BOTTOM_NAVIGATION_LOADED = 'BOTTOM_NAVIGATION_LOADED';
export const BOTTOM_NAVIGATION_SET_POINTS = 'BOTTOM_NAVIGATION_SET_POINTS';
export const BOTTOM_NAVIGATION_UNLOADED = 'BOTTOM_NAVIGATION_UNLOADED';
export const BOTTOM_NAVIGATION_SET_UNREAD_MESSAGES = 'BOTTOM_NAVIGATION_SET_UNREAD_MESSAGES';
export const BOTTOM_NAVIGATION_VISIBILITY = 'BOTTOM_NAVIGATION_VISIBILITY';
export const LEFT_NAVIGATION_OPEN = 'LEFT_NAVIGATION_OPEN';
export const TOP_NAVIGATION_VISIBILITY = 'TOP_NAVIGATION_VISIBILITY';
export const CONTEXT_CHANGE = 'CONTEXT_CHANGE';
export const DISTANCE_CHANGE = 'DISTANCE_CHANGE';
export const SORT_BY_LIKES_CHANGE = 'SORT_BY_LIKES_CHANGE';
export const SORT_BY_DATE_CHANGE = 'SORT_BY_DATE_CHANGE';
export const FILTER_BY_NAME_CHANGE = 'FILTER_BY_NAME_CHANGE';
export const FILTER_BY_DATE_FROM_CHANGE = 'FILTER_BY_DATE_FROM_CHANGE';
export const FILTER_BY_DATE_TO_CHANGE = 'FILTER_BY_DATE_TO_CHANGE';
export const FILTER_BY_STATUS_CHANGE = 'FILTER_BY_STATUS_CHANGE';
export const FILTER_BY_PARTICIPANT_CHANGE = 'FILTER_BY_PARTICIPANT_CHANGE';
export const LATITUDE_CHANGE = 'LATITUDE_CHANGE';
export const LONGITUDE_CHANGE = 'LONGITUDE_CHANGE';
export const PRIVATE_FREAK_PLACE_CHANGE = 'PRIVATE_FREAK_PLACE_CHANGE';
export const PUBLIC_FREAK_PLACE_CHANGE = 'PUBLIC_FREAK_PLACE_CHANGE';
export const RECORDING_FREAK_ROUTE = 'RECORDING_FREAK_ROUTE';
