import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {debounce} from 'lodash';
import 'leaflet/dist/leaflet.css';
import L, {Icon} from 'leaflet'

import TopBar from "../TopBar";
import Divider from '@mui/material/Divider';

import {Global} from '@emotion/react';
import {styled} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MarkerClusterGroup from 'react-leaflet-cluster'
import {MapContainer, Marker, Polyline, TileLayer, useMap, Tooltip, useMapEvents} from "react-leaflet";
import CardContent from "@mui/joy/CardContent";
import {Card, IconButton} from "@mui/material";
import Grid from "@mui/material/Grid";
import agent from "../../agent";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {getAppleMapsLinks, getGoogleMapsLink, getLatLon, isFreakOwner} from "../../utils/freakUtils";
import useTranslation from "../../customHooks/translations";
import FreakPopup from "./FreakPopup";
import ImageViewComponent from "../ImageView/ImageViewComponent";
import Button from "@mui/material/Button";
import EditIcon from '@mui/icons-material/Edit';
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationModal from "../ConfirmationModal";
import {DELETE_FREAK_PLACE, TOP_NAVIGATION_VISIBILITY} from "../../constants/actionTypes";
import SendIcon from '@mui/icons-material/Send';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from '@mui/icons-material/Language';
import FreakListForPlace from "./FreakListForPlace";
import {useMediaQuery} from "react-responsive";
import SmallTopLoader from "../Loaders/SmallTopLoader";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FreakLocalizationTracker from "./FreakLocalizationTracker";
import Box from "@mui/material/Box";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkIcon from '@mui/icons-material/Link';
import Avatar from "@mui/material/Avatar";
import SearchComponent from "../Search/SearchComponent";
import {ALGOLIA_INDEX_NAME_FREAK_PLACES} from "../../constants/commonConstants";
import CommonLoader from "../Loaders/CommonLoader";

const StyledBox = styled('div')(({theme}) => ({
  // backgroundColor: 'white',
}));

const Puller = styled('div')(({theme}) => ({
  width: 30,
  height: 6,
  // backgroundColor: 'white',
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));


const PullerMobile = styled(ExpandMoreIcon)(({theme}) => ({
  width: 30,
  height: 30,
  color: 'white',
  position: 'absolute',
  top: 45,
  left: 'calc(50% - 15px)',
  cursor: 'pointer',
}));

function RecenterAutomatically({position}) {
  const map = useMap();
  useEffect(() => {
    map.setView(position);
  }, [position, map]);

  return null;
}

const FreakPlaces = (props) => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const freakPlaceMapSlug = location?.state?.freakPlaceMapSlug;
  const freakPlaceMap = location?.state?.freakPlaceMap;

  const device = props.device;
  const context = props.context;
  const isDesktop = useMediaQuery({minWidth: 992});

  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState([50.258598, 19.020420]);
  const [freakPlaces, setFreaksPlaces] = useState([]);
  const [filteredFreakPlaces, setFilteredFreakPlaces] = useState([]);
  const [selectedFreakPlace, setSelectedFreakPlace] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [confirmMethod, setConfirmMethod] = useState('');
  const [mapKey, setMapKey] = useState(0);
  const [showMap, setShowMap] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isFreakPlaceExists, setIsFreakPlaceExists] = useState(false);
  const [isPlacesListExpanded, setIsPlacesListExpanded] = useState(false);
  const [mapZoom, setMapZoom] = useState(10);
  const [drawerPlaceLoading, setDrawerPlaceLoading] = useState(false);

  const maxBoundsRef = useRef(null);
  const minZoomRef = useRef(null);
  const mapRef = useRef(null);
  const markerRefs = useRef({});
  const defaultImage = {imageUrl: './logo.png'};
  const mapZoolLabelThreshold = 11;
  const paddingLeft = (isDesktop && props.leftNavigationOpen) ? 300 : (isDesktop && !props.leftNavigationOpen ? 65 : 0);

  useEffect(() => {
    const setCurrentPosition = async () => {
      await getLatLon((position) => {
        setPosition([position.latitude, position.longitude]);
      }, () => {
      });
    };

    setCurrentPosition()
  }, []);

  const fetchVisibleData = async bounds => {
    setIsLoading(true);

    const response = await agent.FreaksPlaces.all(
      context,
      props.publicFreakPlaceFilter === undefined ? true : props.publicFreakPlaceFilter,
      props.privateFreakPlaceFilter === undefined ? true : props.privateFreakPlaceFilter,
      bounds.southWestLat,
      bounds.southWestLon,
      bounds.northEastLat,
      bounds.northEastLon,
    );

    const newMarkers = response?.freaksPlaces?.map(freakPlace => {
      const lat = freakPlace?.map?.coordinates?.latitude;
      const lon = freakPlace?.map?.coordinates?.longitude;
      return {
        lat,
        lon,
        id: freakPlace.id,
        title: freakPlace.title,
        description: freakPlace.description,
        slug: freakPlace.slug,
        images: freakPlace.images,
        map: freakPlace.map,
        public: freakPlace.public,
        type: freakPlace.type,
      }
    });

    if (newMarkers) {
      setFreaksPlaces(prevState => {
        const allMarkers = [...prevState, ...newMarkers];

        const uniqueMarkers = Array.from(new Map(allMarkers.map(marker => [marker.id, marker])).values());

        return uniqueMarkers;
      });
      // @todo get rid of this duplicate
      setFilteredFreakPlaces(prevState => {
        const allMarkers = [...prevState, ...newMarkers];

        const uniqueMarkers = Array.from(new Map(allMarkers.map(marker => [marker.id, marker])).values());

        return uniqueMarkers;
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setFreaksPlaces([]);
    setIsLoading(false);

    const bounds = mapRef?.current?.getBounds();
    if (bounds) {
      const southwest = bounds.getSouthWest();
      const northeast = bounds.getNorthEast();

      const visibleBounds = {
        southWestLat: southwest.lat,
        southWestLon: southwest.lng,
        northEastLat: northeast.lat,
        northEastLon: northeast.lng,
      };

      fetchVisibleData(visibleBounds);
    }
  }, [props.context]);

  useEffect(() => {
    setFreaksPlaces([]);
    setIsLoading(false);

    const handleMapMoveEnd = debounce(() => {
      const bounds = mapRef?.current?.getBounds();
      const zoom = mapRef?.current?.getZoom();

      if (bounds) {
        const southwest = bounds.getSouthWest();
        const northeast = bounds.getNorthEast();

        const visibleBounds = {
          southWestLat: southwest.lat,
          southWestLon: southwest.lng,
          northEastLat: northeast.lat,
          northEastLon: northeast.lng,
        };

        // Check if the new bounds exceed the max bounds
        const isExceedingMaxBounds = !maxBoundsRef.current || (
          visibleBounds.southWestLat < maxBoundsRef.current.southWestLat ||
          visibleBounds.southWestLon < maxBoundsRef.current.southWestLon ||
          visibleBounds.northEastLat > maxBoundsRef.current.northEastLat ||
          visibleBounds.northEastLon > maxBoundsRef.current.northEastLon
        );

        // Check if the zoom level has decreased
        const isZoomLowerThanLastMin = minZoomRef.current === null || zoom < minZoomRef.current;

        if (isExceedingMaxBounds || isZoomLowerThanLastMin) {
          fetchVisibleData(visibleBounds);
          maxBoundsRef.current = {
            southWestLat: visibleBounds.southWestLat,
            southWestLon: visibleBounds.southWestLon,
            northEastLat: visibleBounds.northEastLat,
            northEastLon: visibleBounds.northEastLon,
          };
        }

        if (!minZoomRef.current || zoom < minZoomRef.current) {
          minZoomRef.current = zoom;
        }
      }
    }, 300);

    mapRef?.current?.on('moveend', handleMapMoveEnd);

    handleMapMoveEnd();

    return () => {
      mapRef?.current?.off('moveend', handleMapMoveEnd);
    };
  }, [
    props.context,
    props.privateFreakPlaceFilter,
    props.publicFreakPlaceFilter,
    isModalOpen,
    mapRef.current,
  ]);

  const ZoomHandler = () => {
    useMapEvents({
      zoomend: () => {
        const currentZoom = mapRef.current.getZoom();
        setMapZoom(currentZoom);
      },
    });

    return null;
  };

  const handleOpenModal = (confirmMethod) => {
    setModalOpen(true);
    setConfirmMethod(confirmMethod);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirm = (selectedFreakPlace) => {
    if (confirmMethod === 'delete') {
      props.onClickDelete(agent.FreaksPlaces.del(selectedFreakPlace.id));
    }
    reloadMap();
    handleCloseModal();
  };

  const reloadMap = () => {
    setMapKey(prevKey => prevKey + 1);
    setTimeout(() => setShowMap(true), 100);
  };

  const toggleDrawerList = newOpen => () => {
    setIsPlacesListExpanded(!newOpen);
  };

  const toggleDrawer = (newOpen, freakSlug) => async () => {
    setSelectedFreakPlace(null);
    setOpen(newOpen);

    setDrawerPlaceLoading(true);
    const response = await agent.FreaksPlaces.get(freakSlug, context, 'tablet');
    setSelectedFreakPlace(response.freakPlace);
    setDrawerPlaceLoading(false);
  };
  const drawerBleeding = 0;
  const drawerBleedingMobile = 0; // @todo

  const publicIcon = new Icon({
    iconUrl: '/icons/marker-public-icon.png',
    iconSize: [25, 25],
    iconAnchor: [12, 41]
  });

  const privateIcon = new Icon({
    iconUrl: '/icons/marker-private-icon.png',
    iconSize: [25, 25],
    iconAnchor: [12, 41]
  });

  const myLocationIcon = new Icon({
    iconUrl: '/icons/location-pin.png',
    iconSize: [32, 32],
    iconAnchor: [16, 32]
  });

  const freakLocationIcon = new Icon({
    iconUrl: '/icons/pin.png',
    iconSize: [32, 32],
    iconAnchor: [16, 32]
  });

  // Function to handle when a card is clicked
  const handleCardClick = (place) => {
    const mappedPlace = {
      ...place,
      lat: place.lat || place.map?.coordinates?.latitude,
      lon: place.lon || place.map?.coordinates?.longitude
    };
    setSelectedFreakPlace(mappedPlace);

    // hide SwipeableDrawer
    setIsPlacesListExpanded(false);

    // Scroll to the marker and open the popup
    const marker = markerRefs.current[mappedPlace.id];
    if (marker) {
      mapRef.current.flyTo([mappedPlace.lat, mappedPlace.lon], 13, {duration: 1});
      marker.openPopup();
    } else {
      mapRef.current.flyTo([place.map?.coordinates?.latitude, place.map?.coordinates?.longitude], 13, {duration: 1});

      mapRef.current.once('moveend', () => {
        setTimeout(() => {
          if (markerRefs.current[mappedPlace.id]) {
            markerRefs.current[mappedPlace.id].openPopup();
          }
        }, 4000); // @todo think how to do this better
      });
    }
  };

  const formatBody = (selectedFreakPlace) => selectedFreakPlace?.body?.replace(/\n/g, '<br />');

  const filterPlaces = filterValue => {
    if (!filterValue) {
      setFilteredFreakPlaces(freakPlaces);
      return;
    }

    const loweredFilterValue = filterValue.toLowerCase();
    const filteredPlaces = freakPlaces?.filter(freakPlace => {
      // check description
      // check title
      // check type.name
      if (freakPlace?.description?.toLowerCase()?.includes(loweredFilterValue) ||
        freakPlace?.title?.toLowerCase()?.includes(loweredFilterValue) ||
        freakPlace?.type?.name?.toLowerCase()?.includes(loweredFilterValue)
      ) {
        return freakPlace;
      }
    });

    setFilteredFreakPlaces(filteredPlaces || []);
  };

  const getMarkerIcon = freakPlace => {
    if (freakPlace?.images && freakPlace.images.length) {

      return L.divIcon({
        html: `<div class="circle-icon" style="background-image: url(${freakPlace.images[0].imageUrl});"></div>`,
        iconSize: [25, 25],
        iconAnchor: [12, 12],
        className: 'custom-div-icon'
      });
    } else if (freakPlace.public) {
      return publicIcon;
    } else {
      return privateIcon;
    }
  };

  return (
    <div style={{paddingLeft}}>
      <Box sx={{overflow: 'hidden'}}>
        <TopBar/>
        {
          isDesktop && freakPlaces?.length
            ? <SearchComponent
              indexName={ALGOLIA_INDEX_NAME_FREAK_PLACES}
              context={context}
              handleCardClick={handleCardClick}
            />
            : null
        }
        {
          isLoading
            ? <SmallTopLoader/>
            : null
        }
        {
          showMap ? (
            <Box sx={{
              overflow: 'hidden',
            }}>
              <MapContainer
                center={position}
                zoom={mapZoom}
                style={{
                  height: '100vh',
                  width: '100wh',
                }}
                ref={mapRef}
                attributionControl={false}
                key={mapKey}
                whenReady={() => {
                  setTimeout(() => {
                    mapRef.current?.invalidateSize();
                  }, 500);
                }}
              >
                <ZoomHandler/>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MarkerClusterGroup
                  chunkedLoading
                >
                  {freakPlaces?.map(freakPlace => (
                    <Marker
                      key={freakPlace?.slug}
                      position={[freakPlace.lat, freakPlace.lon]}
                      icon={getMarkerIcon(freakPlace)}
                      ref={(ref) => {
                        markerRefs.current[freakPlace.id] = ref;

                        if (freakPlace?.map?.slug === freakPlaceMapSlug && ref) {
                          setIsFreakPlaceExists(true);
                          setTimeout(() => {
                            ref.openPopup();
                          }, 500);
                        }
                      }}
                      eventHandlers={{
                        click: () => {
                          const loadRoute = async () => {
                            const response = await agent.FreaksPlaces.get(freakPlace.slug, context, 'tablet');
                            setSelectedFreakPlace({
                              ...response.freakPlace
                            });
                          };
                          loadRoute();
                        },
                      }}
                    >
                      {
                        mapZoom >= mapZoolLabelThreshold
                          ? <Tooltip direction="bottom" offset={[0, 0]} permanent>
                            {freakPlace?.title || "Label"}
                          </Tooltip>
                          : null
                      }

                      <FreakPopup
                        title={freakPlace?.title}
                        map={freakPlace?.map}
                        imageUrl={freakPlace?.images?.[0]?.imageUrl || freakPlace.imageUrl}
                        type={freakPlace?.type}
                        onClick={toggleDrawer(true, freakPlace?.slug)}
                      />
                    </Marker>
                  ))}
                  {
                    selectedFreakPlace?.route
                      ? <Polyline
                        pathOptions={{
                          color: DEFAULT_THEME_COLOR,
                          weight: 4,
                          opacity: 0.9,
                          lineJoin: 'round',
                          lineCap: 'round'
                        }}
                        positions={selectedFreakPlace?.route}
                      />
                      : null
                  }
                  {!isFreakPlaceExists && freakPlaceMap?.coordinates?.latitude && freakPlaceMap?.coordinates?.longitude ? (
                    <Marker position={[freakPlaceMap?.coordinates?.latitude, freakPlaceMap?.coordinates?.longitude]}
                            icon={freakLocationIcon}/>
                  ) : null}
                  {position ? <Marker position={position} icon={myLocationIcon}/> : null}
                  <RecenterAutomatically position={position}/>
                </MarkerClusterGroup>
              </MapContainer>
            </Box>
          ) : null
        }

        {!isDesktop ? (
          <>
            <IconButton
              sx={{
                position: 'fixed',
                bottom: 100,
                right: 20,
                zIndex: 1000,
                width: 50,
                height: 50,
                backgroundColor: DEFAULT_THEME_COLOR,
                color: 'white',
                '&:focus': {
                  outline: 'none'
                },
              }}
              onClick={toggleDrawerList(isPlacesListExpanded)}
            >
              <ExpandLessIcon/>
            </IconButton>
            <CssBaseline/>
            <Global/>
            <SwipeableDrawer
              anchor="bottom"
              open={isPlacesListExpanded}
              onClose={toggleDrawerList(false)}
              onOpen={toggleDrawerList(true)}
              swipeAreaWidth={drawerBleedingMobile}
              disableSwipeToOpen={false}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <StyledBox
                sx={{
                  position: 'relative',
                  top: -drawerBleedingMobile,
                  visibility: 'visible',
                  right: 0,
                  left: 0,
                }}
              >
                <PullerMobile onClick={toggleDrawerList(isPlacesListExpanded)}/>
                <Box
                  sx={{
                    padding: 1,
                    marginTop: 10,
                    overflowY: 'auto',
                    height: '100vh',
                  }}
                >
                  <SearchComponent
                    indexName={ALGOLIA_INDEX_NAME_FREAK_PLACES}
                    context={context}
                    handleCardClick={handleCardClick}
                    loadOnStart={true}
                  />
                </Box>
              </StyledBox>
            </SwipeableDrawer>
          </>
        ) : null}

        <CssBaseline/>
        <Global/>
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <StyledBox
            sx={{
              position: isDesktop ? 'relative' : 'absolute',
              top: -drawerBleeding,
              visibility: 'visible',
              right: 0,
              left: 0,
            }}
          >
            <Puller/>
            <Card sx={{
              height: isDesktop ? '100vh' : '90vh',
              boxShadow: 'none',
              overflow: 'auto',
            }}>
              {
                drawerPlaceLoading
                  ? <CommonLoader/>
                  : <>
                    <ImageViewComponent
                      images={selectedFreakPlace?.images?.length ? selectedFreakPlace?.images : [defaultImage]}
                      showThumbnails={false}
                      useOriginalHeight={true}
                      fullScreenButton={'hide'}
                    />
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12} sx={{
                          marginLeft: 0.5,
                          fontWeight: 700,
                          marginTop: 5,
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                        >
                          <Typography variant="h5" fontWeight="bold" gutterBottom align="center">
                            {selectedFreakPlace?.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              marginLeft: 1,
                              fontWeight: 'bold',
                              marginTop: 0.35,
                              display: 'flex',
                              // marginBottom: 5,
                              justifyContent: 'center',
                            }}
                            dangerouslySetInnerHTML={{__html: formatBody(selectedFreakPlace)}}
                          />
                        </Grid>
                        {
                          selectedFreakPlace?.route && selectedFreakPlace?.route?.length
                            ? <Grid item xs={12}>
                              <FreakLocalizationTracker
                                routeCoordinates={selectedFreakPlace?.route}
                                position={position}
                                isRunning={false}
                                zoom={7}
                              />
                            </Grid>
                            : null
                        }
                        <Grid item xs={12}>
                          <Box mt={2} textAlign="center">
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                marginLeft: 1,
                                display: 'flex',
                                marginTop: 5,
                                justifyContent: 'center',
                              }}
                            >
                              {selectedFreakPlace?.map?.description}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              color="text.primary"
                              sx={{
                                marginLeft: 1,
                                fontWeight: 'bold',
                                marginTop: 0.75,
                                fontSize: 13,
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              <IconButton
                                sx={{marginTop: -1,}}
                                component={Link}
                                target="_blank"
                                rel="noopener"
                                to={getGoogleMapsLink(selectedFreakPlace?.map?.coordinates?.latitude, selectedFreakPlace?.map?.coordinates?.longitude)}
                                aria-label="Navigate"
                              >
                                <Avatar
                                  sx={{
                                    width: 45,
                                    height: 45,
                                    borderRadius: 0,
                                    marginTop: 1,
                                  }}
                                  src={'./../icons/google-maps-icon.png'}
                                />
                              </IconButton>
                              <IconButton
                                sx={{marginTop: -1,}}
                                component={Link}
                                target="_blank"
                                rel="noopener"
                                to={getAppleMapsLinks(selectedFreakPlace?.map?.coordinates?.latitude, selectedFreakPlace?.map?.coordinates?.longitude)}
                                aria-label="Navigate"
                              >
                                <Avatar
                                  sx={{
                                    width: 45,
                                    height: 45,
                                    borderRadius: 0,
                                    marginTop: 1,
                                  }}
                                  src={'./../icons/apple-maps-icon.png'}
                                />
                              </IconButton>

                              {
                                selectedFreakPlace?.placeSiteUrl
                                  ? <IconButton
                                    component={Link}
                                    target="_blank"
                                    rel="noopener"
                                    to={selectedFreakPlace.placeSiteUrl}
                                    aria-label="Site"
                                  >

                                    <LanguageIcon sx={{color: DEFAULT_THEME_COLOR, width: 45, height: 45}}/>
                                  </IconButton>
                                  : null
                              }

                              {
                                selectedFreakPlace?.placeYoutubeUrl
                                  ? <IconButton
                                    component={Link}
                                    target="_blank"
                                    rel="noopener"
                                    to={selectedFreakPlace.placeYoutubeUrl}
                                    aria-label="Site"
                                  >
                                    <YouTubeIcon sx={{color: '#FF0000', width: 45, height: 45}}/>
                                  </IconButton>
                                  : null
                              }
                              {
                                selectedFreakPlace?.placeFacebookUrl
                                  ? <IconButton
                                    component={Link}
                                    target="_blank"
                                    rel="noopener"
                                    to={selectedFreakPlace.placeFacebookUrl}
                                    aria-label="Site"
                                  >
                                    <FacebookIcon sx={{color: '#1877F2', width: 45, height: 45}}/>
                                  </IconButton>
                                  : null
                              }
                              {
                                selectedFreakPlace?.placeInstagramUrl
                                  ? <IconButton
                                    component={Link}
                                    target="_blank"
                                    rel="noopener"
                                    to={selectedFreakPlace.placeInstagramUrl}
                                    aria-label="Site"
                                  >
                                    <InstagramIcon sx={{color: 'rgb(30, 48, 80)', width: 45, height: 45}}/>
                                  </IconButton>
                                  : null
                              }
                              {
                                selectedFreakPlace?.otherLinks && selectedFreakPlace?.otherLinks?.length
                                  ? <Grid item>
                                    {selectedFreakPlace?.otherLinks?.map(link => (
                                      <Button
                                        variant="outlined"
                                        style={{
                                          color: DEFAULT_THEME_COLOR,
                                          borderColor: DEFAULT_THEME_COLOR,
                                          'z-index': '0',
                                          borderRadius: 4,
                                          outline: 'none',
                                          marginTop: 12,
                                          marginLeft: 10,
                                          marginRight: 10,
                                          flex: 1,
                                        }}
                                        startIcon={<LinkIcon/>}
                                        onClick={() => {
                                          if (window.cordova && window.cordova.InAppBrowser) {
                                            window.cordova.InAppBrowser.open(link.url, '_system');
                                          } else {
                                            window.open(link.url, '_blank');
                                          }
                                        }}
                                      >
                                        {link.name}
                                      </Button>
                                    ))}
                                  </Grid>
                                  : null
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                        {
                          selectedFreakPlace && selectedFreakPlace.author && isFreakOwner(props.currentUser.username, selectedFreakPlace) && !selectedFreakPlace.public
                            ?
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                marginBottom: '16px',
                              }}
                            >
                              <ButtonGroup
                                sx={{
                                  display: 'flex',
                                  width: '100%',
                                  maxWidth: 520,
                                  gap: 1,
                                  justifyContent: 'center', // Center-align the buttons
                                  flexDirection: 'row',
                                }}
                                orientation="horizontal"
                                aria-label="vertical outlined button group"
                              >
                                <Button
                                  variant="outlined"
                                  style={{
                                    color: DEFAULT_THEME_COLOR,
                                    borderColor: DEFAULT_THEME_COLOR,
                                    'z-index': '0',
                                    borderRadius: 4,
                                    outline: 'none',
                                    marginLeft: 10,
                                    flex: 1,
                                  }}
                                  startIcon={<EditIcon/>}
                                  onClick={() => navigate(`/places-editor/${selectedFreakPlace.slug}`)}
                                >
                                  {translation.freakPlaces.edit}
                                </Button>

                                <Button
                                  variant="outlined"
                                  style={{
                                    color: DEFAULT_THEME_COLOR,
                                    borderColor: DEFAULT_THEME_COLOR,
                                    'z-index': '0',
                                    borderRadius: 4,
                                    outline: 'none',
                                    marginLeft: 10,
                                    marginRight: 10,
                                    flex: 1,
                                  }}
                                  startIcon={<DeleteIcon/>}
                                  onClick={() => handleOpenModal('delete', selectedFreakPlace)}
                                >
                                  {translation.freakPlaces.delete}
                                </Button>
                              </ButtonGroup>
                            </div>

                            : null
                        }
                      </Grid>
                      {
                        selectedFreakPlace?.status === 'waiting_for_approval'
                          ? <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              width: '100%',
                              marginBottom: '16px',
                            }}
                          >
                            <Button
                              variant="outlined"
                              disabled
                              style={{
                                color: DEFAULT_THEME_COLOR,
                                borderColor: DEFAULT_THEME_COLOR,
                                'z-index': '0',
                                borderRadius: 4,
                                margin: 10,
                                outline: 'none',
                                '&:focus': {
                                  outline: 'none'
                                },
                                '&:hover': {
                                  color: DEFAULT_THEME_COLOR,
                                  borderColor: DEFAULT_THEME_COLOR,
                                },
                                maxWidth: 500,
                              }}
                              startIcon={<HourglassBottomIcon/>}
                            >
                              {translation.freakPlaces.waitingForApproval}
                            </Button>
                          </div>
                          : null
                      }
                      {
                        !selectedFreakPlace?.public && selectedFreakPlace?.status !== 'waiting_for_approval'
                          ? <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              width: '100%',
                              marginBottom: '16px',
                            }}
                          >
                            <Button
                              variant="outlined"
                              style={{
                                color: DEFAULT_THEME_COLOR,
                                borderColor: DEFAULT_THEME_COLOR,
                                'z-index': '0',
                                borderRadius: 4,
                                margin: 10,
                                outline: 'none',
                                '&:focus': {
                                  outline: 'none'
                                },
                                '&:hover': {
                                  color: DEFAULT_THEME_COLOR,
                                  borderColor: DEFAULT_THEME_COLOR,
                                },
                                maxWidth: 500,
                              }}
                              startIcon={<SendIcon/>}
                              onClick={() => {
                                agent.FreaksPlaces.sendForPublic(selectedFreakPlace.slug);
                                setOpen(false);
                              }}
                            >
                              {translation.freakPlaces.sendForPublic}
                            </Button>
                          </div>
                          : null
                      }
                      {
                        selectedFreakPlace?.chatId
                          ? <div
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              width: '100%',
                              marginBottom: '16px',
                            }}
                          >
                            <Button
                              variant="outlined"
                              style={{
                                color: DEFAULT_THEME_COLOR,
                                borderColor: DEFAULT_THEME_COLOR,
                                'z-index': '0',
                                borderRadius: 4,
                                margin: 10,
                                outline: 'none',
                                '&:focus': {
                                  outline: 'none'
                                },
                                '&:hover': {
                                  color: DEFAULT_THEME_COLOR,
                                  borderColor: DEFAULT_THEME_COLOR,
                                },
                                maxWidth: 500,
                              }}
                              startIcon={<MailOutlineIcon/>}
                              onClick={() => {
                                navigate('/messages', {
                                  state: {
                                    selectedGroupId: selectedFreakPlace?.chatId,
                                  }
                                })
                              }}
                            >
                              Chat
                            </Button>
                          </div>
                          : null
                      }
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          marginBottom: '16px',
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: DEFAULT_THEME_COLOR,
                            color: 'white',
                            borderColor: DEFAULT_THEME_COLOR,
                            'z-index': '0',
                            borderRadius: 4,
                            margin: 10,
                            maxWidth: 500,
                          }}
                          onClick={() => setOpen(false)}
                        >
                          {translation.contextSwipeableDrawerComponent.close}
                        </Button>
                      </div>
                      <Divider/>

                      {
                        selectedFreakPlace?.map?.Freak?.length
                          ? <div className="container">
                            <div className="row">
                              <Grid item xs={12} sx={{
                                marginLeft: 1,
                                marginTop: 2,
                                fontWeight: '700',
                              }}>
                                <Typography gutterBottom variant="h7" component="div">
                                  {translation.freakPlaces.freaksFromThisPlace}
                                </Typography>
                              </Grid>
                              <div className="col-xs-12 col-md-10 offset-md-1">
                                <FreakListForPlace
                                  freaks={selectedFreakPlace?.map?.Freak}
                                />
                              </div>
                            </div>
                          </div>
                          : <Grid item xs={12} sx={{
                            marginTop: 2,
                          }}
                          >
                            <Typography variant="h5" fontWeight="bold" gutterBottom align="center">
                              {translation.freakPlaces.noFreaksFromThisPlace}
                            </Typography>
                          </Grid>
                      }

                    </CardContent></>
              }
            </Card>
          </StyledBox>
          <ConfirmationModal
            isOpen={isModalOpen} onClose={handleCloseModal}
            onConfirm={() => handleConfirm(selectedFreakPlace)}/>
        </SwipeableDrawer>
      </Box>
    </div>
  )
};

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  device: state.common.device,
  publicFreakPlaceFilter: state.common.publicFreakPlaceFilter,
  privateFreakPlaceFilter: state.common.privateFreakPlaceFilter,
  leftNavigationOpen: state.common.leftNavigationOpen,
});

const mapDispatchToProps = dispatch => ({
  onClickDelete: payload =>
    dispatch({type: DELETE_FREAK_PLACE, payload}),
  setTopNavigationVisible: isVisible => dispatch({
    type: TOP_NAVIGATION_VISIBILITY,
    payload: isVisible
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreakPlaces);
