import {
  ADD_COMMENT,
  DELETE_COMMENT,
  FREAK_PAGE_LOADED,
  FREAK_PAGE_UNLOADED,
  UPLOAD_FREAK_IMAGE,
  UPLOAD_FREAK_VIDEO,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case FREAK_PAGE_LOADED:
      return {
        ...state,
        freak: action?.payload[0]?.freak,
        comments: action?.payload[1]?.comments
      };
    case FREAK_PAGE_UNLOADED:
      return {};
    case ADD_COMMENT:
      return {
        ...state,
        commentErrors: action.error ? action.payload.errors : null,
        comments: action.payload.comments,
      };
    case DELETE_COMMENT:
      return {
        ...state,
        comments: action.payload.comments,
      };
    case UPLOAD_FREAK_IMAGE:
      return {
        ...state,
        imagesToUpload: action.payload ? action.payload.imagesToUpload : '',
        errors: action.error ? action.payload.errors : null
      };
    case UPLOAD_FREAK_VIDEO:
      return {
        ...state,
        videosToUpload: action.payload ? (state.videosToUpload || []).concat(action.payload.videosToUpload) : '',
        errors: action.error ? action.payload.errors : null
      };
    default:
      return state;
  }
};
