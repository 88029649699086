import React, {useEffect, useState} from 'react';
import DeleteButton from './DeleteButton';
import {Link} from 'react-router-dom';
import {formatDateAgo} from '../../utils/dateUtils';
import {GiphyFetch} from "@giphy/js-fetch-api";
import {EmojiVariationsList} from "@giphy/react-components";
import PostCommentComponent from "./PostCommentComponent";
import useTranslation from "../../customHooks/translations";

const gf = new GiphyFetch('sXpGFDGZs0Dv1mmNFvYaGUvYwKX0PWIh'); // @todo

const Comment = props => {
  const translation = useTranslation();
  const show = props.currentUser && props.currentUser.username === props.comment.author.username;

  const [comment, setComment] = useState(null);

  const fetchVariations = (id) => {
    return gf.emojiVariations(id)
  };

  useEffect(() => {
    const prepareComments = async () => {
      let preparedComment = null;
      if (props.comment) {
        preparedComment = { ...props.comment };
        if (preparedComment.gifId) {
          const responseParent = await gf.gif(props.comment.gifId);
          preparedComment.gif = responseParent.data;
        }

        if (props.comment.replies) {
          preparedComment.replies = [];
          for (const reply of props.comment.replies) {
            let preparedReply = { ...reply };
            if (reply.gifId) {
              const responseReply = await gf.gif(reply.gifId);
              preparedReply.gif = responseReply.data;
            }

            preparedComment.replies.push(preparedReply);
          }
        }

        setComment(preparedComment);
      }
    };

    prepareComments();
  }, [props.comment]);

  return comment && !comment?.parent
    ? (
      <div key={comment.id} className="card" style={{marginBottom: 20}}>
        <div className="card-block">
          {comment.gifId ? <EmojiVariationsList
            noLink={true}
            hideAttribution={true}
            fetchVariations={fetchVariations}
            gif={comment.gif}
            gifHeight={100}
          /> : <div dangerouslySetInnerHTML={{__html: comment.body}}/>}
        </div>
        <div className="card-footer">
          <Link
            to={`/@${comment.author.username}`}
            className="comment-author">
            <img
              src={comment.author.imageUrl}
              style={{
                width: 45,
                height: 45,
              }}
              alt={comment.author.username}/>
          </Link>
          &nbsp;
          <Link
            to={`/@${comment.author.username}`}
            className="comment-author">
            {comment.author.username}
          </Link>
          <span className="date-posted">
          {formatDateAgo(comment.createdAt)}
        </span>
          <PostCommentComponent slug={props.slug} text={translation.comments.reply} replyTo={comment.id}/>
          <DeleteButton show={show} slug={props.slug} commentId={comment.id}/>
        </div>
        {comment.replies?.length ? comment.replies.map(reply => (
          <div
            className="card-block m-l-3"
            style={{
              borderLeft: '0.5px solid rgba(255, 255, 255, .65)',
            }}
          >
            <div className="card-block">
              {reply.gifId ? <EmojiVariationsList
                noLink={true}
                hideAttribution={true}
                fetchVariations={fetchVariations}
                gif={reply.gif}
                gifHeight={100}
              /> : <div dangerouslySetInnerHTML={{__html: reply.body}}/>}
            </div>
            <div className="card-footer">
              <Link
                to={`/@${reply.author.username}`}
                className="comment-author">
                <img
                  src={reply.author.imageUrl}
                  style={{
                    width: 45,
                    height: 45,
                  }}
                  alt={reply.author.username}
                />
              </Link>
              &nbsp;
              <Link
                to={`/@${reply.author.username}`}
                className="comment-author">
                {reply.author.username}
              </Link>
              <span className="date-posted">
          {formatDateAgo(reply.createdAt)}
        </span>
              <DeleteButton
                show={props.currentUser && props.currentUser.username === reply.author.username}
                slug={props.slug}
                commentId={reply.id}
              />
            </div>
          </div>)) : null}
      </div>
    )
    : null;
};

export default Comment;
