import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Global} from '@emotion/react';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {IconButton} from "@mui/material";
import {styled} from "@mui/material/styles";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import {Badge as BaseBadge, badgeClasses } from "@mui/base/Badge";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FiltersComponent from "../Filters/FiltersComponent";

const StyledBox = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  overflowY: 'auto',
}));

const Puller = styled('div')(({theme}) => ({
  width: 30,
  height: 6,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const Badge = styled(BaseBadge)(
  ({ theme }) => `
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-self: center;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 1;

  & .${badgeClasses.badge} {
    z-index: auto;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 22px;
    height: 22px;
    padding: 0 0px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
    text-align: center;
    border-radius: 12px;
    background: ${DEFAULT_THEME_COLOR};
    box-shadow: 0px 4px 6x #C7D0DD;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
  }

  & .${badgeClasses.invisible} {
    opacity: 0;
    pointer-events: none;
  }
  `,
);

const FilterSwipeableDrawerComponent = (props) => {
  const [open, setOpen] = useState(false);
  const [filtersBadgeCount, setFiltersBadgeCount] = useState(0);
  const defaultSliderValue = 10000;

  useEffect(() => {
    calculateFiltersCount();
  }, [
    props.sortByLikesFilter,
    props.sortByDateFilter,
    props.filterByNameFilter,
    props.filterByDateFromFilter,
    props.filterByDateToFilter,
    props.filterByStatusFilter,
    props.filterByParticipantFilter,
    props.distanceFilter,
  ]);

  const calculateFiltersCount = () => {
    let filtersCount = 0;
    if (props.sortByDateFilter && props.sortByDateFilter !== 'none') {
      filtersCount++;
    }
    if (props.filterByStatusFilter && props.filterByStatusFilter !== 'none') {
      filtersCount++;
    }

    if (props.sortByLikesFilter && props.sortByLikesFilter !== 'none') {
      filtersCount++;
    }

    if (props.distanceFilter && props.distanceFilter !== defaultSliderValue) {
      filtersCount++;
    }

    if (props.filterByDateFromFilter && props.filterByDateFromFilter !== 'none') {
      filtersCount++;
    }

    if (props.filterByDateToFilter && props.filterByDateToFilter !== 'none') {
      filtersCount++;
    }

    if (props.filterByParticipantFilter && props.filterByParticipantFilter !== 'none') {
      filtersCount++;
    }

    setFiltersBadgeCount(filtersCount);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const drawerBleeding = 0;

  return (
    <>
      <Badge
        badgeContent={filtersBadgeCount}
        color="primary"
      >
        <IconButton
          sx={{
            '&:focus': {
              outline: 'none'
            },
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <FilterAltIcon />
        </IconButton>
      </Badge>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(100% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
      <SwipeableDrawer
        anchor="top"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <Puller/>
          {
            open
              ? <FiltersComponent setOpen={setOpen} pathname={location?.pathname}/>
              : null
          }

        </StyledBox>
      </SwipeableDrawer>
    </>
  )
};

const mapStateToProps = state => ({
  ...state,
  currentUser: state.common.currentUser,
  context: state.common.context,
  device: state.common.device,
  distanceFilter: state.common.distanceFilter,
  publicFreakPlaceFilter: state.common.publicFreakPlaceFilter,
  privateFreakPlaceFilter: state.common.privateFreakPlaceFilter,
  sortByDateFilter: state.common.sortByDateFilter,
  sortByLikesFilter: state.common.sortByLikesFilter,
  filterByNameFilter: state.common.filterByNameFilter,
  filterByDateFromFilter: state.common.filterByDateFromFilter,
  filterByDateToFilter: state.common.filterByDateToFilter,
  filterByStatusFilter: state.common.filterByStatusFilter,
  filterByParticipantFilter: state.common.filterByParticipantFilter,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSwipeableDrawerComponent);
