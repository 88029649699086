import React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useTranslation from "../customHooks/translations";
import {DEFAULT_THEME_COLOR} from "../constants/theme";
import ButtonGroup from "@mui/material/ButtonGroup";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, hideUndone }) => {
  const translation = useTranslation();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24, p: 4 }}
      >
        <Typography id="modal-modal-title"  component="h3">
          {translation.modals.confirmation.areYouSure}
        </Typography>

        {
          hideUndone
           ? null
           :  <Typography id="modal-modal-description">
                {translation.modals.confirmation.actionUndone}
              </Typography>
        }

        <ButtonGroup
          sx={{
            display: 'flex',
            marginTop: 1,
          }}
          orientation="horizontal"
        >
          <Button
            onClick={onConfirm}
            variant="contained"
            size="md"
            style={{
              width: '100%',
              backgroundColor: DEFAULT_THEME_COLOR,
              color: 'white',
              alignSelf: 'flex-end',
              'z-index': '0',
              borderRadius: 4,
              marginRight: 1,
            }}
          >
            {translation.modals.confirmation.confirm}
          </Button>
          <Button
            onClick={onClose}
            size="md"
            variant="contained"
            style={{
              width: '100%',
              backgroundColor: '#e16c6c',
              color: 'white',
              alignSelf: 'flex-end',
              'z-index': '0',
              borderRadius: 4,
              marginRight: 1,
              marginLeft: 10,
            }}
          >
            {translation.modals.confirmation.decline}
          </Button>
        </ButtonGroup>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
