import React from 'react';

import "./styles.css";
import FreakPlaceCard from "../Freak/FreakPlaceCard";

const SearchFreakPlaceResultItem = (props) => {
  const itemData = props.item;

  return (
    <div style={{
      backgroundColor: 'transparent',
      width: '100%',
      height: 150,
      marginTop: 5,
    }}>
      <FreakPlaceCard
        key={itemData.id}
        freakPlace={itemData}
        handleCardClick={() => {
          props.handleCardClick();
          props.setHasQuery(false);
        }}
      />
    </div>
  )
};

export default SearchFreakPlaceResultItem;
