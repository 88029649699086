import React, {useCallback, useState} from 'react'
import {connect} from 'react-redux';
import axios from 'axios';
import {useDropzone} from 'react-dropzone'
import Paper from '@mui/material/Paper';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import ProgressBar from "@ramonak/react-progress-bar";
import {DEFAULT_THEME_COLOR} from "../constants/theme";
import useTranslation from "../customHooks/translations";
import Typography from "@mui/material/Typography";
import agent from "../agent";

const Item = styled('div')(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme?.palette?.background?.level1 : '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: 4,
  color: theme?.vars?.palette?.text?.secondary,
}));

const fileSizeLimitApi = 100000000; // 100mb
const fileSizeLimitVercel = 4000000; // 4mb

const saveFiles = async (
  acceptedFiles,
  device,
  props,
  setProgress,
  setSaveDisabled,
  setUploadFileError,
  translation,
  username,
) => {
  setUploadFileError(null);

  if (setSaveDisabled) {
    setSaveDisabled(true);
  }

  for (let i = 0; i < acceptedFiles.length; i++) {
    const formData = new FormData();
    formData.append('file', acceptedFiles[i]);

    const VERCEL_FUNCTION_BASE_URL = process.env.REACT_APP_VERCEL_FUNCTION_BASE_URL;
    const token = window.localStorage.getItem('jwt');

    const dest = props.dest;
    const fileType = acceptedFiles[0].type;
    const fileSize = acceptedFiles[0].size;
    if (fileSize && fileSize > fileSizeLimitApi) {
      setUploadFileError(translation.uploadFiles.tooLargeFile);
      return;
    }

    if (!fileType.includes('image')) {
      setUploadFileError(translation.uploadFiles.notSupportedFileType)
      return;
    }

    const type = 'images';
    const extension = fileType.split('/')[1];

    // api upload
    // if (fileSize && fileSize < fileSizeLimitApi && fileSize > fileSizeLimitVercel) {
    if (true) { // @todo always upload via API
      try {
        // get pre-signed url
        const { presignedUrl, s3Path } = await agent.Upload.getPreSignedUrl(dest, fileSize);

        // upload to pre-signed url
        await axios.put(presignedUrl, acceptedFiles[i], {
          onUploadProgress: (progress) => {
            const percentCompleted = Math.round((progress.loaded * 100) / progress.total);
            setProgress(Math.round(percentCompleted / (acceptedFiles.length - i)));
          }
        });

        // call api to create resized images
        const results = await agent.Upload.createImagesForDevices({ s3Path, extension, device });

        props.onFileUpload({ data: { ...results }, type });
      } catch (error) {
        const errorJson = JSON.parse(error?.message);
        const errorMessage = errorJson?.errors?.message || translation.uploadFiles.somethingWentWrong;
        setUploadFileError(errorMessage);
      }
    } else { // vercel upload
      const url= `${VERCEL_FUNCTION_BASE_URL}/api/upload?username=${username}&type=${type}&dest=${dest}&device=${device}&extension=${extension}&authorization=${token}`;

      try {
        const results = await axios.post(url, formData, {
          headers: {
            Authorization: `${token}`
          },
          onUploadProgress: (progress) => {
            const percentCompleted = Math.round((progress.loaded * 100) / progress.total);
            setProgress(Math.round(percentCompleted / (acceptedFiles.length - i)));
          }
        });

        props.onFileUpload({ ...results, type });
      } catch (error) {
        const errorJson = JSON.parse(error?.message);
        const errorMessage = errorJson?.errors?.message || translation.uploadFiles.somethingWentWrong;
        setUploadFileError(errorMessage);
      }
    }
  }

  setProgress(0);

  if (setSaveDisabled) {
    setSaveDisabled(false);
  }
};

const UploadFiles = (props) => {
  const translation = useTranslation();
  const device = props.device;
  const [progress, setProgress] = useState(0);
  const [uploadFileError, setUploadFileError] = useState(null);

  const onDrop = useCallback(async (acceptedFiles) => {
    return await saveFiles(
      acceptedFiles,
      device,
      props,
      setProgress,
      props.setSaveDisabled,
      setUploadFileError,
      translation,
      props?.currentUser?.username
    );
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <Paper {...getRootProps()}
           sx={{boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'}}
    >
      <input {...getInputProps()} />
      <Grid container>
        <Grid xs={12}>
          <Item>
            {
              uploadFileError
                ? <Typography variant="h6" sx={{color: 'red'}}>
                  {uploadFileError}
                </Typography>
                : null
            }
          </Item>
          <Item>
            <CloudUploadIcon sx={{ width: 40, height: 40}} />
          </Item>
          {progress ? <ProgressBar completed={progress} bgColor={DEFAULT_THEME_COLOR}/> : null}
        </Grid>
      </Grid>
    </Paper>
  );
}

const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  device: state.common.device,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFiles);
